import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";

import firebaseApp from "../database";

import moment from 'moment';

import Avatar from "../avatar";

import AppointmentsService from "../../services/appointmentsService";

import { getDate } from "../../utils";
import PatientsService from '../../services/patientsService';
import { GlobalContext } from '../../GlobalContext';



export default function AppointmentsPage() {

    let navigate = useNavigate();

    const [patient, setPatient] = useState(null);

    const [appointments, setAppointments] = useState([]);

    const {setIsLoading} = useContext(GlobalContext);

    useEffect(() => {
        firebaseApp.auth().onAuthStateChanged(function (user) {
            if (user) {

                console.log(`patient: ${user.uid}`);

                setIsLoading(true)
                // Get patient data
                PatientsService.getPatient(user.uid)
                    .then((result) => {
                        setPatient(result);

                        // get appointments for patient
                        if (result) {
                            AppointmentsService.getAppointments(user.uid)
                                .then((appointmentsResults) => {

                                    // sort by date
                                    appointmentsResults.sort((a, b) => getDate(b.start) - getDate(a.start));

                                    const confirmedList = appointmentsResults.filter( a => a.status === undefined || a.status === "confirmed");

                                    setAppointments(confirmedList);

                                    setIsLoading(false);
                                })
                                .catch(() => { setIsLoading(false); });
                        } else {
                            setIsLoading(false);
                        }

                    })
                    .catch(() => { setIsLoading(false); });



            } else {
                // User is signed out.
                setPatient(null);
                setAppointments([]);
            }
        });

    }, []);



    function getAppointmentString(appointment) {
        if (appointment) {
            const startDate = moment(getDate(appointment.start));

            return `${startDate.format("dddd - DD.MM.YYYY - HH:mm")}`;
        }

        return "";
    }

    async function onDeleteButtonClick(appointment, doctor) {
        const startDate = moment(getDate(appointment.start));

        const doctorString = appointment.doctorFullName;
        const locationId = appointment.locationId;

        const dateString = `${startDate.format("dddd - DD.MM.YYYY - HH:mm")}`;
        let reallyDelete = window.confirm(`Wollen Sie Ihren Termin am ${dateString} wirklich stornieren?`);

        if (reallyDelete) {

            // remove from array to have an instant ui update
            setAppointments(appointments.filter(a => a.id !== appointment.id));

            await AppointmentsService.setLocationAppointmentStatus(appointment, patient, "declined");

            // now ask if patient wants to make a new appointment at that doctor
            let makeNewAppointment = window.confirm(`Möchten Sie nun einen neuen Termin bei ${doctorString} vereinbaren?`);

            if (makeNewAppointment) {
                navigate(`/profile/${doctor.clientId}/${locationId}/${doctor.id}`);
            }

        }

    }

    function updateAppointments() {
        if (patient) {
            AppointmentsService.getAppointments(patient.id)
                .then((appointmentsResults) => {
                    setAppointments(appointmentsResults);
                })
        }
    }


    function renderAppointment(appointment, canBeDeleted) {

        const doctor = {
            id: appointment.doctorId,
            clientId: appointment.clientId
        };

        return doctor && (
            <div key={appointment.id} className="kt-user-appointment">
                <div>
                    <Link to={`/profile/${doctor.clientId}/${appointment.locationId}/${doctor.id}`}>
                        <Avatar doctor={doctor} alt="" width="40px" />
                    </Link>
                </div>
                <div>
                    <div>{appointment.doctorFullName.replace("Herr ", "").replace("Frau ", "")}</div>
                    <Link to={`/appointment/${doctor.clientId}/${appointment.locationId}/${appointment.id}`}>
                        <div className="kt-appointment-label"><strong>{getAppointmentString(appointment)}</strong></div>
                    </Link>
                    <div>{appointment.nameForPatient ? appointment.nameForPatient : appointment.visitMotiveName}</div>
                </div>
                <div className="kt-user-appointment-buttons">
                    <div>TERMIN</div>
                    {doctor.allowOnlineAppointments && <div className="kt-btn" onClick={() => navigate(`/profile/${doctor.clientId}/${appointment.locationId}/${doctor.id}`)}><i className="fal fa-plus"></i> ANLEGEN</div>}
                    {canBeDeleted && <div className="kt-btn" onClick={() => onDeleteButtonClick(appointment, doctor)}><i className="fal fa-trash-alt"></i> ABSAGEN</div>}
                </div>

            </div>
        )

    }

    function renderAppointments(_appointments) {
        const today = new Date();
        const pastAppointments = _appointments.filter(a => getDate(a.start) < today);
        const futureAppointments = _appointments.filter(a => getDate(a.start) >= today);

        // sort by date
        pastAppointments.sort((a, b) => getDate(b.start) - getDate(a.start));
        futureAppointments.sort((a, b) => getDate(a.start) - getDate(b.start));

        return (
            <div className="kt-user-appointments">

                {futureAppointments.length > 0 &&
                    <div>
                        <h2>Ihre anstehenden Termine</h2>
                        {futureAppointments.map((appointment) => renderAppointment(appointment, true))}
                    </div>
                }

                {pastAppointments.length > 0 &&
                    <div>
                        <h2>Ihre vergangenen Termine</h2>
                        {pastAppointments.map((appointment) => renderAppointment(appointment))}
                    </div>
                }

                {(futureAppointments.length === 0 && pastAppointments.length === 0) &&
                    <div>
                        <h2>Ihre Termine</h2>
                        <div>Sie haben bis jetzt noch keine Termine gebucht.</div>
                    </div>
                }


            </div>
        );
    }

    return (
        <div className="kt-appointmentsPage">

            <div className="kt-body">

                {patient ?
                    <div className="kt-panel">
                        <div className="kt-panel-content">
                            {renderAppointments(appointments)}
                        </div>
                    </div>
                    :
                    <div className="kt-panel">
                        <div className="kt-panel-content">
                            <h2>Bitte loggen Sie Sich zuerst ein, um Ihre Termine zu sehen.</h2>
                            <p><Link to={"/login"}>Hier geht es zum LOGIN</Link></p>

                            <p><Link to={"/register"}>Oder erstellen Sie hier einen NEUEN ACCOUNT</Link></p>
                        </div>
                    </div>
                }


            </div>



        </div>
    );
}