import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";

import firebaseApp from "../database";

import moment from 'moment';

import Avatar from "../avatar";

import AppointmentsService from "../../services/appointmentsService";
import ClientsService from "../../services/clientsService";
import UsersService from "../../services/usersService";

import {getFullUserName, getDate, scrollToTop, getDateString} from "../../utils";
import PatientsService from '../../services/patientsService';
import LoggingService from '../../services/loggingService';
import { LogItemType } from '../../models/logItem';


export default function BookingConfirmationPage() {

    let navigate = useNavigate();

    let { appointmentId } = useParams();

    const [appointment, setAppointment] = useState<any>(null);
    const [doctor, setDoctor] = useState<any>(null);
    const [client, setClient] = useState<any>(null);

    const [isAppointmentConfirmed, setIsAppointmentConfirmed] = useState(false);
    const [appointmentNotFound, setAppointmentNotFound] = useState(false);

    const [authUser, setAuthUser] = useState<any>(null);
    const [patient, setPatient] = useState<any>(null);

    useEffect(() => {
        firebaseApp.auth().onAuthStateChanged(function(user) {
            if (user) {
                // User is signed in.
                setAuthUser(user as any);

                PatientsService.getPatient(user.uid)
                .then((result) => {
                    setPatient(result);
                });

            } else {
                // User is signed out.
                setAuthUser(null);
                setPatient(null);
            }
          });

    }, []);

    useEffect(()=>{

        scrollToTop();

        if(appointmentId){
            AppointmentsService.getAppointment(appointmentId)
            .then((result) => {

                setAppointmentNotFound(result === null || result === undefined);

                setAppointment(result);
                setIsAppointmentConfirmed(result && result.status !== "reserved");

                if(result && result.calendar){
                    UsersService.getUserByCalendarId(result.clientId, result.locationId, result.calendar.id)
                    .then((doc) => {
                        setDoctor(doc);

                        ClientsService.getClient(doc.clientId)
                        .then((cl) => {
                            setClient(cl);
                        })
                    })
                }

            })
            .catch((err) => {
                setAppointmentNotFound(true);
            })
        }

    }, [appointmentId]);

    useEffect(() => {
        if(isAppointmentConfirmed){
            setTimeout(() => {
                navigate(`/appointments`);
            }, 2000);
        }
        // eslint-disable-next-line
    }, [isAppointmentConfirmed])

    function getAppointmentString() {
        if(appointment){
            const startDate = moment(getDate(appointment.start));

            return `${startDate.format("dddd - DD.MM.YYYY - HH:mm")}`;
        }

        return "";
    }

    function canRender(){
        return appointment && doctor && client && !appointmentNotFound;
    }

    function onConfirmButtonClick(){

        if(!appointmentId) {
            setAppointmentNotFound(true);
            return;
        }

        let newAppointment = appointment;
        newAppointment.patient = {
            id: patient.id,
            firstName: patient.firstName,
            lastName: patient.lastName
        };

        newAppointment.status = "confirmed";

        // first get the appointment from db to check if it still exists
        // or if it was cleaned up in the meantime
        AppointmentsService.getAppointment(appointmentId)
        .then((result) => {
            if(result) {

                if(!newAppointment.resourceId && newAppointment.calendar && newAppointment.calendar.id){
                    newAppointment.resourceId = newAppointment.calendar.id;
                }

                AppointmentsService.updateAppointment(newAppointment, patient, false)
                .then(() => {

                    // send a confirmation SMS or email to the patient if allowed
                    AppointmentsService.confirmAppointment(newAppointment);

                    // remove appointment from db root reserved list
                    AppointmentsService.deleteAppointment(newAppointment, patient, true);

                    setIsAppointmentConfirmed(true);

                    const doctorName = doctor.title ? `${doctor.title} ${doctor.lastName}` : doctor.lastName;
                    const message = `Patient ${patient.firstName} ${patient.lastName} hat einen Online-Termin ${newAppointment.visitMotive.name} für den ${getDateString(newAppointment.start)} bei ${doctorName} erstellt`;
                    LoggingService.log(LogItemType.onlineAppointmentBooked, newAppointment.id, patient.id, doctor.id, message, newAppointment.clientId, newAppointment.locationId);
                })
                .catch((err) => {
                    setAppointmentNotFound(true);
                });
            } else {
                setAppointmentNotFound(true);
            }
        })
        .catch((err) => {
            setAppointmentNotFound(true);
        });

    }

    function onBackButtonClick(){
        // remove appointment from db root reserved list
        AppointmentsService.deleteAppointment(appointment, patient, true)
        .then(() => {
            window.history.back();
        })
        .catch((err) => {
            window.history.back();
        });
    }

    return (
        <div className="kt-bookingConfirmationPage">

            {canRender() && <div className="kt-body">

                <div className="kt-panel">
                    <div className="kt-panel-content">
                        <div className="kt-profile-row">
                            <div className="kt-panel-column">
                                <Avatar doctor={doctor} alt="" width="60px"/>
                            </div>
                            <div className="kt-panel-column">
                                <div><strong>{getFullUserName(doctor)}</strong></div>
                                <div>{client.street}</div>
                                <div>{client.postalCode} {client.city}</div>
                            </div>
                        </div>

                        <div className="kt-appointment-row">
                            <div><h2 className="kt-no-margin-bottom">{getAppointmentString()}</h2></div>
                            <div><h3>{appointment.visitMotive.nameForPatient ? appointment.visitMotive.nameForPatient : appointment.visitMotive.name}</h3></div>
                            <br/>
                            {!isAppointmentConfirmed && <div className="kt-btn" onClick={onBackButtonClick}>TERMIN ÄNDERN</div>}

                            {(!isAppointmentConfirmed && !(authUser && authUser.phoneNumber)) && <div>Bitte melden Sie Sich zuerst an um diesen Termin zu bestätigen.</div>}

                        </div>
                    </div>
                </div>

                {isAppointmentConfirmed ?
                    <div className="kt-panel">
                        <div className="kt-panel-content">
                            <h2>Vielen Dank, wir freuen uns auf Sie!</h2>
                        </div>
                    </div>
                    :
                    <div>{(authUser && authUser.phoneNumber) &&
                        <div className="kt-panel">
                            <div className="kt-panel-content">
                                <div className="kt-btn-big kt-btn-secondary-color" onClick={onConfirmButtonClick}>TERMIN BESTÄTIGEN</div>
                            </div>
                        </div>}
                    </div>
                }



                {!(authUser && authUser.phoneNumber) &&
                    <div className="kt-panel">
                        <div className="kt-panel-content">

                            <div className="kt-center">Neu auf Pick-A-Doc?</div>
                            <Link to={`/register/${appointmentId}`} className="kt-btn-big">REGISTRIEREN</Link>

                        </div>
                    </div>}

                    {!(authUser && authUser.phoneNumber) &&
                    <div className="kt-panel">
                        <div className="kt-panel-content">

                            <div className="kt-center">Sie haben bereits ein Pick-A-Doc Konto?</div>
                            <Link to={`/login/${appointmentId}`} className="kt-btn-big kt-btn-secondary-color">ANMELDEN</Link>

                            <div className="kt-history-back-link" onClick={e => window.history.back()}><i className="far fa-angle-left"></i> Zurück</div>

                        </div>
                    </div>
                }



            </div>
            }

            {appointmentNotFound &&
                <div className="kt-body">
                    <div className="kt-panel">
                        <div className="kt-panel-content">
                            <h2>Dieser Termin existiert nicht mehr!</h2>

                            <span>Nicht bestätigte Termine werden nach 15 Minuten wieder freigegeben. Bitte buchen Sie erneut einen Termin.</span>

                            <div className="kt-history-back-link" onClick={e => window.history.back()}><i className="far fa-angle-left"></i> Zurück</div>
                        </div>
                    </div>
                </div>
            }


        </div>
    );
}