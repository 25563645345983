import firebaseApp from "./../components/database";

class ClientLocationsService {

    static getLocation(clientId, locationId) {

        return new Promise((resolve, reject) => {

            var db = firebaseApp.firestore();
            db.collection(`clients/${clientId}/locations`)
                .doc(locationId)
                .get()
                .then((doc) => {

                    var location = null;

                    if (doc.exists) {
                        location = doc.data();
                        location.id = locationId;

                        // fix, for wrong stored property
                        if(location.openingHours) location.openingHours.enabled = true;

                    } else {
                        console.log("getLocation: No such document: " + clientId);
                    }

                    resolve(location);
                })
                .catch((error) => {
                    console.log("Error getting location: ", error);
                });
        });
    };

}

export default ClientLocationsService;