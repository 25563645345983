import React from 'react';

export default function TermsPanel(props) {


  return (
  <div>
    <h2><strong>Allgemeine Geschäftsbedingungen der</strong></h2>

    <p><strong>Pick-a-Doc GmbH</strong><br/>
    Oststraße 34A<br/>
    40211 Düsseldorf<br/>
    vertreten durch den Geschäftsführer Herrn Dr. Michael Petsas, ebenda<br/>
    E-Mail: info@pickadoc.de</p>

<h3>1. Geltungsbereich, Leistungen von Pick-a-Doc GmbH</h3>

<p>(1) Unsere Allgemeinen Geschäftsbedingungen gelten für sämtliche Geschäftsbeziehungen zwischen:
Pick-a-Doc GmbH, Oststraße 34A, 40211 Düsseldorf (nachfolgend „Pick-a-Doc“ genannt) und ihren Kunden (im Folgenden auch: „Kunden“ oder „Nutzer“). Unsere Leistungen erfolgen ausschließlich zu unseren Allgemeinen Geschäftsbedingungen (AGB) in ihrer zum Zeitpunkt der Bestellung der jeweiligen Dienstleistung gültigen Fassung. Hiervon abweichende vertragliche Vereinbarungen gelten nur, sofern sie schriftlich von Pick-a-Doc bestätigt worden sind.</p>

<p>(2) Die Dienstleistungen von Pick-a-Doc umfassen im Wesentlichen die Bereitstellung eines Online-Terminkalenders für Zahnärzte, welchen diese gegenüber ihren Patienten zur unkomplizierten und vereinfachten Vereinbarung von Behandlungs- und Vorsorgeterminen zur Verfügung stellen können. Die zu diesem Zwecke durch Pick-a-Doc zur Verfügung gestellte Software kann den verwendenden Ärzten und ihren Patienten die Nutzung weiterer Tools, wie Benachrichtigungs-, Informations- und Erinnerungsdienstleistungen, unverbindliche Gesundheitsinformationen, Datenspeicherungsdienstleistungen für Kalender- und Patientenstammdaten, Dienste im Zusammenhang mit der Erneuerung von ärztlichen Verschreibungen und Rezepten oder Videosprechstunden bieten. Diese Liste an möglichen Tools ist weder verbindlich, noch abschließend.</p>

<h3>2. Einbeziehung der AGB in das Vertragsverhältnis, Zweck</h3>

<p>(1) Die AGB beziehen sich auf sämtliche von Pick-a-Doc insbesondere über ihren Internetauftritt unter www.pickadoc.de für die Kunden und Nutzer bereitgehaltenen Dienste und gelten auch für sämtliche zukünftigen Rechtsgeschäfte zwischen Pick-a-Doc und dem Nutzer. Eine aktuelle Version der AGB kann auf der vorbezeichneten Webseite abgerufen werden.</p>

<p>(2) Der Kunde erteilt bei Vertragsschluss für die Nutzung der Dienstleitungen von Pick-a-Doc die ausdrückliche Zustimmung zur Anwendbarkeit der AGB in ihrer jeweils geltenden Fassung. Mit seiner Zustimmung bestätigt der Kunde auch, alle AGBs gelesen und verstanden zu haben. Der Nutzer hat dafür Sorge zu tragen, dass auch seine Angestellten und Erfüllungsgehilfen die Dienstleistungen von Pick-a-Doc ordnungsgemäß unter Beachtung der AGB nutzen.</p>

<p>(3) Die AGB regeln sämtliche Bedingungen für den Zugang und die Nutzung der unter Ziffer 1 dieser AGB bezeichneten Dienstleistungen und Anwendungen von Pick-a-Doc durch den Kunden und die damit zusammenhängenden Rechte und Pflichten der Parteien.</p>

<h3>3. Leistungen von Pick-a-Doc</h3>

<p>(1) Pick-a-Doc stellt allein die Funktionalität seiner Dienstleistungen sowie deren Erreichbarkeit im vertraglich definierten Umfang zur Verfügung.</p>

<p>(2) Pick-a-Doc ist stets bemüht, für einen hohe Funktionalität seiner Dienstleistungen/Anwendungen Sorge zu tragen, um die Kontinuität und Qualität der Dienstleistungen zu gewährleisten. Das jeweilige konkrete Leistungsangebot, der Leistungsumfang und die Kosten werden dem Kunden beim Ablauf des Online-Bestellvorgangs detailliert beschrieben.</p>

<p>(3) Nach Abschluss des Bestellvorgangs erhält der Kunde eine Bestätigung der bestellten Dienstleistungen per E-Mail an diejenige Adresse, welche im Rahmen der Aufnahme der Kundendaten anzugeben ist.</p>

<h3>4. Vertragsdauer und Kündigung</h3>

<p>(1) Die jeweils gültige Dauer einer Vertragsbeziehung zwischen Pick-a-Doc und dem Kunden ergibt sich aus dem jeweils verwendeten Bestellvorgang.</p>
<p>(2) Sollte sich aus dem jeweiligen konkreten Bestellbedingungen nichts Abweichendes ergeben, kann ein Vertrag jederzeit mit einer Frist von einem Monat zum Ende eines Kalendermonats von den Parteien schriftlich (per Post, Fax) oder per E-Mail gekündigt werden.</p>
<p>(3) Das Recht zur außerordentlichen Kündigung aus wichtigem Grund bleibt unberührt. Diese sind insbesondere bei schwerwiegenden Verstößen gegen vertragliche Pflichten anzunehmen.</p>

<h3>5. Gebühren, Zahlungsbedingungen</h3>

<p>(1) Die Nutzung von Pick-a-Doc ist für Patienten kostenfrei.</p>

<h3>6. Pflichten des Kunden</h3>

<p>(1) Der Kunde ist für sämtliche Inhalte seiner Angebote selbst verantwortlich. Im Rahmen der Nutzung der Dienstleistungen von Pick-a-Doc ist der Kunde für die Einhaltung des geltenden Rechts sowie die Beachtung der Rechte Dritter verantwortlich.</p>

<p>(2) Der Nutzer trägt für die Richtigkeit und die Aktualität der an Pick-a-Doc weitergegebenen und vertragsrelevanten Daten selbst Sorge und ist selbst für die Sicherung seiner Daten verantwortlich.</p>

<p>(3) Die terminliche Kommunikation zwischen den nutzenden Ärzten und deren Patienten fällt ausschließlich in den Verantwortungsbereich des Nutzers. Dabei ist der Nutzer auch für die Einhaltung geltenden Rechts, insbesondere den Datenschutz, sowie besondere Informationspflichten gegenüber den Patienten selbst verantwortlich.</p>

<p>(4) Der Nutzer erteilt Pick-a-Doc mit Vertragsabschluss das widerrufliche Recht, den Nutzer in Veröffentlichungen gleich welcher Art als Referenzkunden auszuweisen und in diesem Zusammenhang Namen, Marken und Logos des Nutzers zu verwenden. Dieses Recht gilt so lange als eingeräumt, bis der Nutzer dieser Rechteeinräumung in Textform ausdrücklich widerspricht.</p>

<h3>7. Zugangssperrung</h3>

<p>Pick-a-Doc behält sich das ausdrückliche Recht vor, einen Kundenzugang in folgenden Fällen zu sperren:
<ul>
    <li>Angabe falscher oder unvollständiger Kundendaten</li>
    <li>Verletzung von Vertragspflichten</li>
    <li>Rechtswidrige Nutzung des Dienstes (insbesondere bei Verstoß gegen Strafrecht und/oder Verbotsvorschriften)</li>
    <li>Unbefugte Weitergabe von Dienstleistung von Pick-a-Doc</li>
</ul>
</p>

<h3>8. Haftung von Pick-a-Doc</h3>

<p>(1) Die Haftung von Pick-a-Doc im Rahmen seiner Dienstleistungen ist gegenüber dem Nutzer auf Vorsatz oder grobe Fahrlässigkeit beschränkt. Die Haftung für leichte Fahrlässigkeit ist der Höhe nach beschränkt auf die bei Vertragsschluss vorhersehbaren, adäquat kausalen Schäden.</p>

<p>(2) Die Haftung wegen einer schuldhaften Verletzung des Lebens, des Körpers oder der Gesundheit bleiben von der vorstehenden Haftungsbeschränkung unberührt.</p>

<p>(3) Insbesondere kann Pick-a-Doc nicht für einen Schaden der Patienten und/oder eines nutzenden Arztes haftbar gemacht werden, welcher sich auf einem fehlerhaften Gebrauch der Dienstleistungen und Anwendungen von Pick-a-Doc bezieht.</p>

<h3>9. Personenbezogene Daten</h3>

<p>Die Vertragsparteien sind dazu verpflichtet, die ab dem 25. Mai 2018 geltende Verordnung (EU) 2016/679 vom 27. April 2016 (DSGVO) einzuhalten. Die von Pick-a-Doc durchgeführte Verarbeitung personenbezogener Daten ist in den Pick-a-Doc-Richtlinien zur Verwendung personenbezogener Daten beschrieben, die unter der folgenden Adresse abrufbar sind: <a href="https://pickadoc.de/data-protection" target="_blank" rel="noopener noreferrer">https://pickadoc.de/data-protection</a></p>

<p>Pick-a-Doc erkennt an und akzeptiert, dass die vom Kunden über seine Dienstleistungen verarbeiteten personenbezogenen Daten unter das Berufsgeheimnis (Art 203 StGB) fallen.</p>

<h3>10. Geistiges Eigentum</h3>

<p>(1) Eigentumsrechte an den Komponenten der zur Verfügung gestellten Dienstleistungen und Anwendungen von Pick-a-Doc werden nicht auf den Nutzer übertragen.</p>

<p>(2) Pick-a-Doc räumt dem Kunden ein persönliches, nicht ausschließliches und nicht übertragbares Nutzungsrecht an den ihm im Rahmen des Vertrages zur Verfügung gestellten Dienstleistungen ein. Dieses Recht wird für die Dauer des Vertrages gewährt.</p>

<p>(3) Dem Nutzer ist es nicht gestattet, die Funktionalität der durch Pick-a-Doc ggf. zur Verfügung gestellten Software und deren Komponenten nachzustellen, zu dekompilieren, Komponenten zu zerlegen und/oder als Grundlage für die Erstellung eigener Softwareprogramme zu verwenden.</p>

<h3>11. Übertragbarkeit der Vertragsbeziehung</h3>

<p>Der Kunde ist bereits jetzt damit einverstanden, dass Pick-a-Doc die Rechte und Pflichten aus diesem Vertrag, in welcher rechtlichen Form auch immer, an einen Dritten abtritt, überträgt oder einbringt. Pick-a-Doc wird dabei die berechtigten Interessen der Nutzer nicht beeinträchtigen.</p>

<h3>12. Datenschutz</h3>

<p>(1) Pick-a-Doc nutzt die erhobenen Kundendaten ausschließlich zur Abwicklung seiner Dienstleistungen und zur Rechnungslegung. Sämtliche personenbezogenen Daten werden vertraulich und unter Beachtung aller datenschutzrechtlichen Grundsätze behandelt. Details ergeben sich aus der Datenschutzerklärung von Pic-a-Doc, welche insbesondere über den Webauftritt unter www.pickadoc.de abrufbar ist.</p>

<p>(2) Die Speicherung und Nutzung von personenbezogenen Daten der Kunden des Nutzers erfolgt in dessen Auftrag gemäß §11 BDSG ausschließlich nach den Vorgaben des Nutzers und unter Beachtung der Grundsätze ordnungsgemäßer Datenverarbeitung und unter Einhaltung der gesetzlich vorgeschriebenen Datenschutzbestimmungen. Soweit erforderlich oder gewünscht wird der Anbieter mit dem Nutzer eine weitergehende Regelung zur Auftragsdatenverarbeitung abschließen.</p>

<h3>13. Geheimhaltung</h3>

<p>(1) Die Parteien verpflichten sich dazu, den Inhalt ihrer schriftlichen oder mündlichen Informationen jeglicher Art in Zusammenhang mit den Dienstleistungen von Pick-a-Doc, von denen sie im Rahmen des Vertrages Kenntnis haben, vertraulich zu behandeln.</p>

<p>(2) Diese Verpflichtung gilt nicht für solche Daten und Informationen, welche als allgemein bekannt gelten oder der jeweils anderen Vertragspartei nachweislich bereits vor Vertragsschluss bekannt waren.</p>

<p>(3) Diese Vertraulichkeitsvereinbarung gilt über die Dauer des Vertragsverhältnisses hinaus.</p>

<h3>14. Online-Streitbeilegung</h3>

<p>Die Europäische Kommission stellt die Europäische Plattform für Online-Streitbeilegung (OS-Plattform) bereit, um den Online-Einkauf durch den Zugang zu Streitbeilegungsinstrumenten von guter Qualität sicherer und fairer zu gestalten. Weitere Information unter <a href="http://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">http://ec.europa.eu/consumers/odr/</a></p>

<p>Pick-a-Doc nimmt nicht an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil.</p>

<h3>15. Änderung dieser AGB</h3>

<p>(1) Pick-a-Doc behält sich das Recht vor, den Inhalt dieser AGB, Art und Umfang der angebotenen Dienstleistungen und entsprechende Gebühren jederzeit zu ändern, soweit dies aus triftigen Gründen, insbesondere aufgrund einer geänderten Rechtslage oder höchstrichterlichen Rechtsprechung, technischer Änderungen oder Weiterentwicklungen, neuer organisatorischer Anforderungen des Massenverkehrs, Regelungslücken in den AGB, Veränderung der Marktgegebenheiten oder anderen gleichwertigen Gründen erforderlich ist und den Nutzer nicht unangemessen benachteiligt.</p>

<p>(2) Pick-a-Doc teilt dem Kunden die geänderten Bedingungen umgehend, spätestens jedoch vier Wochen vor ihrem Inkrafttreten schriftlich oder per E-Mail mit und weist dabei ausdrücklich auf das Recht des Kunden hin, der Geltung der geänderten Bedingungen, Leistungen oder Gebühren innerhalb einer Frist von sechs Wochen (beginnend nach Zugang der schriftlichen Änderungsmitteilung) schriftlich oder per E-Mail zu widersprechen. Falls der Kunde nicht fristgerecht widerspricht, wird das Vertragsverhältnis zu den geänderten Bedingungen, Leistungen und/oder Gebühren fortgesetzt. Widerspricht der Kunde fristgerecht, ist Pick-a-Doc berechtigt, das Vertragsverhältnis ordentlich nach Maßgabe der vorstehenden Ziffer 4 (2) zu kündigen.</p>

<h3>16. Anwendbares Recht und Gerichtsstand</h3>

<p>(1) Nebenabreden zu diesen AGB bestehen nicht. Änderungen und Ergänzungen zu diesen AGB bedürfen der Schriftform. Dies gilt auch für die Regelung hinsichtlich des Schriftformerfordernisses.</p>
<p>(2) Für gewerbliche und/oder freiberufliche Kunden wird – sofern gesetzlich zulässig - der Gerichtsstand Düsseldorf vereinbart.</p>
<p>(3) Es gilt das Recht der Bundesrepublik Deutschland. UN-Kaufrecht (CISG) kommt nicht zur Anwendung.</p>
<p>(4) Sollte eine Bestimmung dieser AGB unwirksam sein oder werden, bleiben diese im Übrigen gültig. Anstelle der unwirksamen Bestimmung soll dann dasjenige gelten, was die Parteien in Kenntnis der Unwirksamkeit der einzelnen AGB-Klauseln vereinbart hätten, um den wirtschaftlichen Erfolg dieser Klausel herbeizuführen. Das Gleiche gilt im Fall von Regelungslücken.</p>

Stand dieser AGB: 10/2021

</div>
  );
}