
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { getDate, getDateString, getDiffInMinutes, getOpeningHoursByDayIndex, getTimeString, isSameDay } from "./../utils";

import AppointmentsService from "./../services/appointmentsService";
import CalendarsService from "./../services/calendarsService";


import moment from 'moment';
import PublicHoliday from '../models/publicHoliday';


interface Props {
    isLoading: boolean;
    minDate?: Date | null;
    doctor: any;
    patient?: any;
    appointment?: any;
    appointments: any[];
    publicHolidays: PublicHoliday[];
    locationId: any;
    visitMotiveId: any;
    visitMotives: any[];
    locationOpeningHours: any;
    onIsBookingChange: (newBookingState: boolean) => void;
    onCurrentDateChange: (newCurrentDate: Date) => void;
}

const FreeTimeSlotsCtrl: React.FC<Props> = ({ isLoading, minDate, doctor, patient, appointment, appointments, publicHolidays, locationId, visitMotiveId, visitMotives, locationOpeningHours, onIsBookingChange, onCurrentDateChange }) => {

    const navigate = useNavigate();

    const [weekDays, setWeekDays] = useState<moment.Moment[]>([]);
    const [startDate, setStartDate] = useState(moment().startOf('week'));
    const [currentDate, setCurrentDate] = useState(moment().startOf('week'));
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<moment.Moment | null>(null);
    const [freeTimeSlots, setFreeTimeSlots] = useState<any[]>([]);
    const [gotoNextWeekCounter, setGotoNextWeekCounter] = useState(0);
    const [isPreviousButtonEnabled, setIsPreviousButtonEnabled] = useState(false);

    useEffect(() => {

        const _startDate = getDate(minDate ?? new Date());
        setStartDate(moment(_startDate).startOf("week"));

        if (currentDate && currentDate.isBefore(_startDate)) {

            setCurrentDate(moment(_startDate));

            updateWeekDays(_startDate);
        }

        // console.log("FreeTimeSlotsCtrl ##########################################");
        // console.log(`_startDate: ${_startDate}`);
        // console.log(`minDate: ${minDate}`);
        // console.log(`doctor: `, doctor);
        // console.log(`appointment: ${appointment}`);
        // console.log(`visitMotiveId: ${visitMotiveId}`);
        // console.log(`publicHolidays: `, publicHolidays);
        

    }, []);

    useEffect(() => {

        if (currentDate) updateWeekDays(currentDate);

    }, [currentDate]);

    function updateWeekDays(_currentDate: moment.Moment) {
        const tempWeekDays: moment.Moment[] = [];
        for (var i = 0; i < 7; i++) {
            let day = moment(_currentDate).add(i, "day");
            tempWeekDays.push(day);
        }
        setWeekDays(tempWeekDays);

        setIsPreviousButtonEnabled(_currentDate > startDate.startOf('week'));
    }

    function isPublicHoliday(date: moment.Moment): boolean {

        if (publicHolidays) {
            for (let i = 0; i < publicHolidays.length; i++) {
                const pH = publicHolidays[i].start;

                if (isSameDay(pH, date.toDate())) {
                    //console.log("is public holiday: ", date.toLocaleString());
                    return true;
                }

            }
        }
        return false;
    }

    useEffect(() => {

        // if(doctor) console.log(doctor.id + ": " + doctor.lastName);
        // if (minDate) console.log("minDate: " + minDate.toString());
        // if (currentDate) console.log("currentDate: " + currentDate.toString());
        // console.log("visitMotiveId: " + visitMotiveId);


        function getFreeTimeSlots(appointments) {

            const _minDate = minDate ? getDate(minDate) : null;

            const openingHours = (doctor.openingHours && doctor.openingHours.enabled) ? doctor.openingHours : locationOpeningHours;

            if (!appointments || !openingHours || !visitMotiveId) {
                return [];
            }


            const slotsWeek: any[] = [];
            const slotDuration = 30; // selectedMotive ? selectedMotive.duration : 30; // minutes

            const now = moment();

            let freeSlotsCount = 0;

            const startIndex = currentDate.day();

            // create days
            // d = 0 => monday
            for (let d = 0; d < 7; d++) {
                const slotsDay: moment.Moment[] = [];

                const dayOpeningHours = getOpeningHoursByDayIndex(openingHours, d, startIndex);

                // skip day if doctor has closed
                if (dayOpeningHours && dayOpeningHours.hasOpen) {

                    const workingMinutesPerDay = (dayOpeningHours.open.end.hour * 60 + dayOpeningHours.open.end.minute) - (dayOpeningHours.open.start.hour * 60 + dayOpeningHours.open.start.minute);

                    const slotsPerDay = workingMinutesPerDay / slotDuration;

                    // console.log("slotsPerDay: ", slotsPerDay );

                    const currentDayDate = moment(currentDate).hour(dayOpeningHours.open.start.hour).minute(dayOpeningHours.open.start.minute).add(d, "days");

                    // skip day if its a public holiday
                    // or if day is before minDate
                    if (!isPublicHoliday(currentDayDate) && ((_minDate && currentDayDate >= moment(_minDate)) || !_minDate)) {
    
                        // console.log("currentDayDate: ", currentDayDate.toLocaleString());

                        // create slots per day
                        for (let s = 0; s < slotsPerDay; s++) {
                            const timeSlotDateStart = moment(currentDayDate).add(s * slotDuration, "minutes");
                            const timeSlotDateEnd = moment(timeSlotDateStart).add(slotDuration, "minutes");

                            //console.log(`timeSlotDate start: ${timeSlotDateStart.toLocaleString()} end: ${timeSlotDateEnd.toLocaleString()} }`);

                            // skip slots which where in the past
                            // now check if there is an appointment already for that time slot interval
                            if ((!minDate || timeSlotDateStart > moment(minDate)) && timeSlotDateStart > now && !isSlotIntersectingWithAnyAppointments(timeSlotDateStart, timeSlotDateEnd, appointments)
                                && !(dayOpeningHours.hasPause && isSlotIntersectingPause(timeSlotDateStart, timeSlotDateEnd, dayOpeningHours.pause))) {

                                freeSlotsCount++;
                                slotsDay.push(timeSlotDateStart);
                            } else {
                                //console.log("blocked slot: ", timeSlotDateStart.toLocaleString() );
                            }

                        }
                    }

                }

                slotsWeek.push(slotsDay);
            }

            if (freeSlotsCount === 0) {
                // if no free appointments in this week, goto next week
                // try this only two times
                if (gotoNextWeekCounter < 2) {

                    const newCurrentDate = moment(currentDate).add(1, "week");

                    setCurrentDate(newCurrentDate);
                    setGotoNextWeekCounter(gotoNextWeekCounter + 1);

                    //onCurrentDateChange(newCurrentDate.toDate());

                    // console.log("no free slots available, checking next week..");
                }
            }

            // console.log("freeSlotsCount: ", freeSlotsCount);

            return slotsWeek;
        }

        setFreeTimeSlots(getFreeTimeSlots(appointments));
        // eslint-disable-next-line
    }, [visitMotiveId, appointments, doctor, currentDate]);


    useEffect(() => {
        setGotoNextWeekCounter(0);
    }, [visitMotiveId, doctor]);


    function isSlotIntersectingWithAnyAppointments(timeSlotDateStart: moment.Moment, timeSlotDateEnd: moment.Moment, appointments) {
        let isIntersecting = false;

        for (let a = 0; a < appointments.length; a++) {
            const appointment = appointments[a];

            if (isSlotIntersectingAppointment(timeSlotDateStart, timeSlotDateEnd, appointment)) {
                isIntersecting = true;
                break;
            }
        }

        return isIntersecting;
    }

    function isSlotIntersectingAppointment(timeSlotDateStart: moment.Moment, timeSlotDateEnd: moment.Moment, appointment) {

        const appointmentStart = moment(appointment.start.setSeconds(0,0));
        const appointmentEnd = moment(appointment.end.setSeconds(0,0));

        const _timeSlotDateStart = moment(timeSlotDateStart).seconds(0).milliseconds(0);
        const _timeSlotDateEnd = moment(timeSlotDateEnd).seconds(0).milliseconds(0);

        // timeSlot: 9:30-10:00 appointment: 9:45-10:15
        if(appointmentStart >= _timeSlotDateStart && appointmentStart < _timeSlotDateEnd) return true;

        // timeSlot: 9:30-10:00 appointment: 9:00-9:45
        if(appointmentEnd > _timeSlotDateStart && appointmentEnd <= _timeSlotDateEnd) return true;

        // timeSlot: 9:30-10:00 appointment: 9:00-11:00
        if(appointmentStart <= _timeSlotDateStart && appointmentEnd > _timeSlotDateEnd) return true;

        return false;
    }


    function isSlotIntersectingPause(timeSlotDateStart: moment.Moment, timeSlotDateEnd: moment.Moment, pause) {

        const pauseStart = moment(timeSlotDateStart).hour(pause.start.hour).minute(pause.start.minute);
        const pauseEnd = moment(timeSlotDateEnd).hour(pause.end.hour).minute(pause.end.minute);

        return (timeSlotDateStart >= pauseStart && timeSlotDateStart < pauseEnd) ||
            (timeSlotDateEnd > pauseStart && timeSlotDateEnd < pauseEnd) ||
            (pauseStart >= timeSlotDateStart && pauseStart < timeSlotDateEnd) ||
            (pauseEnd > timeSlotDateStart && pauseEnd < timeSlotDateEnd);
    }

    // create a temporary appointment
    // so that this timeslot will be reserved
    // until the user confirms the appointment or it will be deleted after 15 minutes
    async function reserveAppointment(timeSlot, selectedMotive, calendarIds) {

        // time slot is still free
        // now reserve that time slot for the user
        // and goto the confirmation page

        if (locationId && selectedMotive && timeSlot && calendarIds && calendarIds.length > 0) {

            // get calendar name for that doctor
            const calendar = await CalendarsService.getCalendar(doctor.clientId, locationId, calendarIds[0])

            if (!calendar) return;

            const appointment = {
                start: timeSlot.toDate(),
                end: moment(timeSlot).add(selectedMotive.duration, "minutes").toDate(),
                clientId: calendar.clientId,
                locationId: locationId,
                calendar: {
                    id: calendarIds[0],
                    name: calendar.name,
                    userId: doctor.id
                },
                patient: {
                    id: patient ? patient.id : "",
                    firstName: patient ? patient.firstName : "",
                    lastName: patient ? patient.lastName : ""
                },
                visitMotive: {
                    id: selectedMotive.id,
                    name: selectedMotive.name,
                    nameForPatient: selectedMotive.nameForPatient ? selectedMotive.nameForPatient : "",
                    specialityId: selectedMotive.specialityId
                },
                status: "reserved",
                createdAt: new Date()//firebase.database.ServerValue.TIMESTAMP
            }

            const appointmentId = await AppointmentsService.updateAppointment(appointment, patient, true)

            if (appointmentId) {
                navigate(`/confirmation/${appointmentId}`);
            }


        } else {
            onIsBookingChange(false);
        }
    }


    async function isTimeSlotStillFree(timeSlot, calendarIds, selectedMotive) {
        // get updated appointments to check if slot is still free
        if (doctor && doctor.clientId && selectedMotive && currentDate) {

            const endDate = moment(currentDate).add(6, "day").toDate();

            const pAppointments = await AppointmentsService.getPublicAppointments(doctor.clientId, locationId, currentDate.toDate(), endDate, calendarIds)

            if (!pAppointments) return false;


            // first check if the slot is still free
            // or if somebody else has booked an appointment in that timespan
            const timeSlotDateStart = moment(timeSlot);
            const timeSlotDateEnd = moment(timeSlotDateStart).add(selectedMotive.duration, "minutes");

            // skip slots which where in the past
            // now check if there is an appointment already for that time slot interval
            if (isSlotIntersectingWithAnyAppointments(timeSlotDateStart, timeSlotDateEnd, pAppointments)) {
                alert("Der Termin ist leider in der Zwischenzeit bereits vergeben worden. Bitte wählen Sie eine andere Uhrzeit aus.");

                return false;
            }
        }
    }


    async function handleTimeSlotClick(timeSlot: moment.Moment) {

        onIsBookingChange(true);

        if (appointment && (appointment.status === "needsConfirmation" || appointment.status === "confirmed")) {
            if (!window.confirm(`Bitte bestätigen Sie, dass Sie Ihren Termin am ${getDateString(timeSlot.toDate())} um ${getTimeString(timeSlot.toDate())} wahrnehmen werden.`)) {
                onIsBookingChange(false);
                return;
            }
        }


        if (doctor && doctor.clientId) {

            let calendarIds: any[] = [];

            if (doctor && doctor.calendarIds) {
                calendarIds = doctor.calendarIds;

            } else if (doctor && doctor.calendarIds) {
                calendarIds = doctor.calendarIds;
            }

            const selectedMotive = visitMotives.find(m => m.id === visitMotiveId);

            if (!isTimeSlotStillFree(timeSlot, calendarIds, selectedMotive)) {
                onIsBookingChange(false);
                return;
            }



            setSelectedTimeSlot(timeSlot);

            try {
                if (appointment && (appointment.status === "needsConfirmation" || appointment.status === "confirmed")) {

                    const diffInMinutes = getDiffInMinutes(appointment.start, appointment.end);
                    appointment.start = timeSlot.toDate();
                    appointment.end = new Date(appointment.start.getTime() + diffInMinutes * 60000);

                    if (appointment.status === "needsConfirmation") {
                        await AppointmentsService.confirmRecallAppointment(appointment);
                    } else {
                        await AppointmentsService.postponeAppointment(appointment);
                    }

                    navigate(`/thankyou`);

                } else {
                    await reserveAppointment(timeSlot, selectedMotive, calendarIds);
                }

            } catch (err) {
                console.error(err);
                alert("Wir konnten den Termin nicht für Sie reservieren. Bitte versuchen Sie es später noch einmal.");
            }

        }

        onIsBookingChange(false);

    }

    function gotoPreviousWeek() {
        const newDateMoment = moment(currentDate).add(-1, "week");

        setCurrentDate(newDateMoment);
        onCurrentDateChange(newDateMoment.toDate());
    }

    function gotoNextWeek() {
        const newDateMoment = moment(currentDate).add(1, "week");

        setCurrentDate(newDateMoment);
        onCurrentDateChange(newDateMoment.toDate());
    }


    function renderFreeTimeSlots() {
        return (
            <div>
                <div className="kt-booking-calendar-header">
                    {weekDays.map((day, index) =>
                        <div key={index}><div>{day.format("dd")}</div><div>{`${day.date()}.${day.month() + 1}`}</div></div>
                    )}
                </div>

                <div className="kt-booking-calendar-time-slots">
                    {freeTimeSlots.map((dayTimeSlots, dayIndex) =>
                        <div key={dayIndex} className="kt-day-slots-column">
                            {dayTimeSlots.map((timeSlot, slotIndex) =>
                                <div key={slotIndex} onClick={() => handleTimeSlotClick(timeSlot)} className={selectedTimeSlot === timeSlot ? "kt-selected" : ""}>
                                    {timeSlot.format("HH:mm")}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    if (!currentDate) {
        return <div></div>
    }

    if (isLoading) {
        return (
            <div className='kt-booking-loading-wrapper'>
                <p>Wir suchen freie Termine für Sie...</p>
                <i className="fas fa-spinner fa-pulse"></i>
            </div>
        );
    }

    return (

        <div className="kt-booking-calendar">
            <div className="kt-booking-calendar-navigation">
                {isPreviousButtonEnabled ?
                    <div className="kt-btn-enabled" onClick={gotoPreviousWeek}><i className="fal fa-chevron-circle-left" aria-hidden="true"></i></div>
                    :
                    <div><i className="fal fa-chevron-circle-left" aria-hidden="true"></i></div>
                }

                <div>{`${currentDate.date()}.${currentDate.format("MMMM")} - ${moment(currentDate).add(6, "day").date()}.${moment(currentDate).add(6, "day").format("MMMM")} ${currentDate.format("YYYY")}`}</div>
                <div className="kt-btn-enabled" onClick={gotoNextWeek}><i className="fal fa-chevron-circle-right" aria-hidden="true"></i></div>
            </div>
            {renderFreeTimeSlots()}
        </div>

    );
}

export default FreeTimeSlotsCtrl;