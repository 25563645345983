import React, { useState, useRef, useEffect } from "react";


const mimeType = "audio/mp4";

export enum AudioRecorderStatus {
    inactive,
    recording,
    finished,
    error
}

interface Props {
    onStatusChanged?: (newStatus: AudioRecorderStatus) => void
    onFinishedRecording?: (audioUrl: string, audioBlob: Blob) => void
}

const AudioRecorder: React.FC<Props> = ({onStatusChanged, onFinishedRecording}) => {
    const mediaRecorder = useRef<MediaRecorder | null>(null);

    const [permission, setPermission] = useState(false);
    const [recordingStatus, setRecordingStatus] = useState(AudioRecorderStatus.inactive);
    const [stream, setStream] = useState<MediaStream | null>(null);
    const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
    const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
    const [audio, setAudio] = useState("");


    useEffect(() => {
        if(onStatusChanged) onStatusChanged(recordingStatus);

        if(onFinishedRecording && recordingStatus === AudioRecorderStatus.finished && audioBlob){
            onFinishedRecording(audio, audioBlob);
            
            //releaseStream();
            
        }

    }, [recordingStatus]);

    const releaseStream = () => {
        if(stream !== null){
            stream.getTracks().forEach(track => track.stop());
        }

        setStream(null);
    }

    const resetRecorder = () => {
        setRecordingStatus(AudioRecorderStatus.inactive);
        setStream(null);
    }


    const getMicrophonePermission = async (): Promise<{ _permission: boolean, _stream: MediaStream | null }> => {
        if ("MediaRecorder" in window) {
            try {

                console.log("getMicrophonePermission");

                const streamData = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: false,
                });
                setPermission(true);
                setStream(streamData);

                return { _permission: true, _stream: streamData }

            } catch (err) {
                alert((err as any).message);
            }
        } else {
            alert("Ihr Browser unterstützt keine Audioaufnahmen.");
        }

        setRecordingStatus(AudioRecorderStatus.error);

        return { _permission: false, _stream: null }
    };

    const startRecording = async () => {

        let _permission = permission;
        let _stream = stream;

        if (!_permission || !stream) {
            const result = await getMicrophonePermission();
            _permission = result._permission;
            _stream = result._stream;
        }

        if (_permission && _stream) {
            setRecordingStatus(AudioRecorderStatus.recording);

            try {
                //create new Media recorder instance using the stream
                const media = new MediaRecorder(_stream);

                //set the MediaRecorder instance to the mediaRecorder ref
                mediaRecorder.current = media;

                //invokes the start method to start the recording process
                mediaRecorder.current.start();
                let localAudioChunks: Blob[] = [];
                mediaRecorder.current.ondataavailable = (event: BlobEvent) => {
                    if (typeof event.data === "undefined") return;
                    if (event.data.size === 0) return;
                    localAudioChunks.push(event.data);
                };
                setAudioChunks(localAudioChunks);

            } catch(err){
                releaseStream();
                console.error(err);
            }

        }

    };

    const stopRecording = () => {

        if (mediaRecorder && mediaRecorder.current) {
            //stops the recording instance
            mediaRecorder.current.stop();
            mediaRecorder.current.onstop = () => {
                //creates a blob file from the audiochunks data
                const _audioBlob = new Blob(audioChunks, {type: mimeType});
                //creates a playable URL from the blob file.
                const audioUrl = URL.createObjectURL(_audioBlob);
                setAudioBlob(_audioBlob)
                setAudio(audioUrl);
                setAudioChunks([]);

                setRecordingStatus(AudioRecorderStatus.finished);

            };

        }

    };

    return (
        <div className="kt-audio-recorder">
            <main>
                <div className="audio-controls">
                    {recordingStatus === AudioRecorderStatus.inactive ? (
                        <button className="kt-btn" onClick={startRecording} type="button">
                            <i className="fal fa-microphone"></i> AUFNAHME STARTEN
                        </button>
                    ) : null}
                    {recordingStatus === AudioRecorderStatus.recording ? (
                        <div style={{textAlign: "center"}}>
                            <span className="kt-pulse"></span>
                            <span>Audio wird aufgenommen</span>
                            
                            <button className="kt-btn" onClick={stopRecording} type="button" style={{marginTop:"20px"}}>
                                AUFNAHME BEENDEN
                            </button>    
                        </div>
                        
                    ) : null}
                </div>

                {audio ? (
                    <div className="audio-container">
                        <audio src={audio} controls></audio>
                    </div>
                ) : null}

            </main>
        </div>
    );
};


export default AudioRecorder;