import firebaseApp from "./../components/database";

const db = firebaseApp.firestore();

const VisitMotivesService = {

    async getVisitMotive(visitMotiveId, clientId, locationId): Promise<any | null> {

        console.log("VisitMotivesService.getVisitMotive");

        try {
            const doc = await db.collection("clients")
                .doc(clientId)
                .collection("locations")
                .doc(locationId)
                .collection("visitMotives")
                .doc(visitMotiveId)
                .get();

            if (doc.exists) {

                let visitMotive: any = null;

                if (doc.exists) {
                    visitMotive = doc.data();
                    visitMotive.id = visitMotiveId;

                    // load now landing page data
                    const successorVMotive = await VisitMotivesService.getSuccessorVisitMotive(visitMotive, clientId, locationId);
                    visitMotive.successorLandingPage = successorVMotive ? successorVMotive.landingPage : null;


                    const recallVMotive = await VisitMotivesService.getRecallVisitMotive(visitMotive, clientId, locationId);
                    visitMotive.recallLandingPage = recallVMotive ? recallVMotive.landingPage : null;


                } else {
                    console.log("getVisitMotive: No such document: " + visitMotiveId);
                }

                return visitMotive;

            } else {
                console.log("getVisitMotive: No such document: " + visitMotiveId);
            }

        } catch (error) {
            console.log("Error getting visit motive: ", error);
        }

        return null;
    },

    async getSuccessorVisitMotive(visitMotive, clientId, locationId): Promise<any | null> {

        if(!visitMotive || !visitMotive.successorEnabled || !visitMotive.successorId) return null;

        console.log("VisitMotivesService.getSuccessorVisitMotive");

        try {
            const doc = await db.collection("clients")
                .doc(clientId)
                .collection("locations")
                .doc(locationId)
                .collection("visitMotives")
                .doc(visitMotive.successorId)
                .get();

            if (doc.exists) {

                let visitMotive: any = null;

                if (doc.exists) {
                    visitMotive = doc.data();
                    visitMotive.id = visitMotive.successorId;

                } else {
                    console.log("getSuccessorVisitMotive: No such document: " + visitMotive.successorId);
                }

                return visitMotive;

            } else {
                console.log("getSuccessorVisitMotive: No such document: " + visitMotive.successorId);
            }

        } catch (error) {
            console.log("Error getting successor visit motive: ", error);
        }

        return null;
    },

    async getRecallVisitMotive(visitMotive, clientId, locationId): Promise<any | null> {

        if(!visitMotive || visitMotive.recurrenceCount === 0 || !visitMotive.recallId) return null;

        console.log("VisitMotivesService.getRecallVisitMotive");

        try {
            const doc = await db.collection("clients")
                .doc(clientId)
                .collection("locations")
                .doc(locationId)
                .collection("visitMotives")
                .doc(visitMotive.recallId)
                .get();

            if (doc.exists) {

                let visitMotive: any = null;

                if (doc.exists) {
                    visitMotive = doc.data();
                    visitMotive.id = visitMotive.recallId;

                } else {
                    console.log("getRecallVisitMotive: No such document: " + visitMotive.recallId);
                }

                return visitMotive;

            } else {
                console.log("getRecallVisitMotive: No such document: " + visitMotive.recallId);
            }

        } catch (error) {
            console.log("Error getting recall visit motive: ", error);
        }

        return null;
    },


    async getAllVisitMotives(clientId, locationId): Promise<any[] | null> {

        console.log("VisitMotivesService.getAllVisitMotives");

        try {

            const querySnapshot = await db.collection(`clients/${clientId}/locations/${locationId}/visitMotives`)
                .orderBy("name")
                .get();

            const visitMotiveList: any[] = [];

            querySnapshot.forEach((doc) => {
                if (doc.exists) {
                    const visitMotive = doc.data();
                    visitMotive.id = doc.id;
                    visitMotiveList.push(visitMotive);
                }
            });

            return visitMotiveList;


        } catch (error) {
            console.log("Error getting visit motives: ", error);
        }

        return null;

    },

    async getOnlineVisitMotives(clientId, locationId): Promise<any[] | null> {

        console.log("VisitMotivesService.getOnlineVisitMotives");

        try {

            const querySnapshot = await db.collection(`clients/${clientId}/locations/${locationId}/visitMotives`)
                .orderBy("name")
                .where("allowOnlineBooking", "==", true)
                .get();

            const visitMotiveList: any[] = [];

            querySnapshot.forEach((doc) => {

                const visitMotive = doc.data();

                visitMotive.id = doc.id;
                visitMotiveList.push(visitMotive);
            });


            return visitMotiveList;


        } catch (error) {
            console.log("Error getting visit motives: ", error);
        }

        return null;

    }
}

export default VisitMotivesService;