import React, { useState, useEffect } from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Link
} from "react-router-dom";

import firebaseApp from "firebase/compat/app";

import { GlobalContext } from './GlobalContext';

import moment from 'moment';
import "moment/min/locales.min";

import ContactPage from "./components/pages/contactPage";
import DataProtectionPage from "./components/pages/dataProtectionPage";
import LegalNoticePage from "./components/pages/legalNoticePage";
import TermsPage from "./components/pages/termsPage";

import RegisterPage from "./components/pages/registerPage"
import LoginPage from "./components/pages/loginPage"
import LoginRegisterPage from "./components/pages/loginRegisterPage"
import PasswordRecoveryPage from "./components/pages/passwordRecoveryPage"

import AppointmentsPage from "./components/pages/appointmentsPage";
import DoctorPage from "./components/pages/doctorPage";
import BookingConfirmationPage from './components/pages/bookingConfirmationPage';
import Error404Page from './components/pages/Error404Page';
import RatingPage from './components/pages/ratingPage';
import AppointmentPage from "./components/pages/appointmentPage";
import HomePage from './components/pages/home/homePage';
import ThankYouPage from './components/pages/thankYouPage';
import InstallationPage from './components/pages/installationPage';
import VoicePlaygroundPage from './components/pages/home/voicePlaygroundPage';
import CalendrPlaygroundPage from './components/pages/home/calendrPlaygroundPage';
import ClonrPlaygroundPage from './components/pages/home/clonrPlayground';
import ClonrPlaygroundLandingPage from './components/pages/home/clonrPlaygroundLandingPage';
import { ThemeProvider, createTheme } from '@mui/material/styles';

function App() {

    moment.locale("de");

    const [authUser, setAuthUser] = useState<any | null>(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        firebaseApp.auth().onAuthStateChanged(function (user) {
            if (user) {
                // User is signed in.
                setAuthUser(user);

                // user.getIdTokenResult()
                // .then((idTokenResult) => {

                //     // Confirm the user is a patient or admin
                //     if (idTokenResult.claims.role !== "patient" && idTokenResult.claims.role !== "admin") {
                //         firebase.auth().signOut();
                //         alert("Sie sind nicht berechtigt sich mit diesem Account anzumelden. Dies ist kein Patienten-Account.");
                //     }
                // });


            } else {
                // User is signed out.
                setAuthUser(null);
            }
        });


    }, []);


    function onLogoutButtonClick() {
        firebaseApp.auth().signOut();
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: '#24374e'
            },
            secondary: {
                main: '#d862c8'
            }
        }
    });

    function Header() {
        return <div className="kt-header">

            <img src="/images/logo.svg" width={32} alt="logo" />

            <span className="navbar-brand-name">Pick-A-Doc</span>
            {(authUser && authUser.phoneNumber) ?
                <div className="kt-header-buttons">
                    <Link to={'/appointments'} className="kt-logout-button"><i className="fal fa-calendar-alt"></i> Termine</Link>
                    <div className="kt-logout-button" onClick={onLogoutButtonClick}><i className="fal fa-sign-out-alt"></i> Abmelden</div>
                </div>
                :
                <Link to={"/login-register"} className="kt-login-button"><i className="fal fa-sign-in-alt"></i> Anmelden</Link>
            }
        </div>
    }

    function Footer() {
        return <div className="kt-footer">
            <div className="kt-footer-content">
                <div className="kt-footer-row">
                    <Link to={`/contact`}>Kontakt</Link>
                    <Link to={`/terms`}>AGB</Link>
                    <Link to={`/legal-notice`}>Impressum</Link>
                    <Link to={`/data-protection`}>Datenschutz</Link>
                </div>
                <div className="kt-footer-row">
                    <span>&copy; 2024 Pickadoc.de</span>
                </div>
            </div>
        </div>
    }

    return (
        <ThemeProvider theme={theme}>
            <GlobalContext.Provider value={{
                isLoading, setIsLoading
            }}>
                <div className="App">

                    <BrowserRouter>

                        <Routes>

                            <Route path="/rating/:ratingId" element={<>
                                <Header />
                                <RatingPage />
                                <Footer />
                            </>} />

                            <Route path="/contact" element={<>
                                <Header />
                                <ContactPage />
                                <Footer />
                            </>} />

                            <Route path="/terms" element={<>
                                <Header />
                                <TermsPage />
                                <Footer />
                            </>} />

                            <Route path="/legal-notice" element={<>
                                <Header />
                                <LegalNoticePage />
                                <Footer />
                            </>} />

                            <Route path="/data-protection" element={<>
                                <Header />
                                <DataProtectionPage />
                                <Footer />
                            </>} />

                            <Route path="/thankyou" element={<>
                                <Header />
                                <ThankYouPage />
                                <Footer />
                            </>} />

                            <Route path="/login/:appointmentId?" element={<>
                                <Header />
                                <LoginPage />
                                <Footer />
                            </>} />

                            <Route path="/register/:appointmentId?" element={<>
                                <Header />
                                <RegisterPage />
                                <Footer />
                            </>} />

                            <Route path="/login-register/:appointmentId?" element={<>
                                <Header />
                                <LoginRegisterPage />
                                <Footer />
                            </>} />

                            <Route path="/password-recovery/:appointmentId?" element={<>
                                <Header />
                                <PasswordRecoveryPage />
                                <Footer />
                            </>} />

                            <Route path="/confirmation/:appointmentId" element={<>
                                <Header />
                                <BookingConfirmationPage />
                                <Footer />
                            </>} />

                            <Route path="/appointments" element={<>
                                <Header />
                                <AppointmentsPage />
                                <Footer />
                            </>} />

                            <Route path="/profile/:clientId/:locationId/:userId?" element={<>
                                <Header />
                                <DoctorPage />
                                <Footer />
                            </>} />

                            <Route path="/recall/:clientId/:locationId/:appointmentId" element={<>
                                <Header />
                                <AppointmentPage />
                                <Footer />
                            </>} />

                            <Route path="/appointment/:clientId/:locationId/:appointmentId/:status?" element={<>
                                <Header />
                                <AppointmentPage />
                                <Footer />
                            </>} />

                            <Route path="/installation" element={<>
                                <InstallationPage />
                            </>} />



                            <Route path="/playgrounds/voice" element={<>
                                <VoicePlaygroundPage />
                            </>} />

                            <Route path="/playgrounds/calendr" element={<>
                                <CalendrPlaygroundPage />
                            </>} />

                            <Route path="/playgrounds/clonr" element={<>
                                <ClonrPlaygroundPage />
                            </>} />
                            <Route path="/playgrounds/clonrlp/:videoId" element={<>
                                <ClonrPlaygroundLandingPage />
                            </>} />

                            <Route path="/" element={<>
                                <HomePage />
                            </>} />


                            <Route path="*" element={<>
                                <Header />
                                <Error404Page />
                                <Footer />
                            </>} />

                        </Routes>

                    </BrowserRouter>

                </div>

                {isLoading && <div className="kt-loading-screen">
                    <div className="kt-loading-screen-content">
                        <div className="kt-loading-icon"><i className="fal fa-spin fa-spinner-third"></i></div>
                        <div>LADEN...</div>
                    </div>
                </div>}

            </GlobalContext.Provider>
        </ThemeProvider>
    );
}

export default App;
