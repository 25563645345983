import React from 'react';
import { getUUID } from '../utils';

interface Props {
    label: string;
    checked: boolean;
    onChange: (newValue: boolean) => void;
}

const SwitchCtrl: React.FC<Props> = ({ label, checked, onChange }) => {


    const id = getUUID();


    return (

        <div className="kt-switch-ctrl">

            <div className='kt-switch-ctrl-wrapper'>
                <input
                    className='kt-switch'
                    id={id}
                    type="checkbox"
                    checked={checked}
                    onChange={e => onChange(e.currentTarget.checked)}
                />
                <label htmlFor={id}>{label}</label>
            </div>

        </div>

    );
}

export default SwitchCtrl;