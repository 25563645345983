import React, { useState, useRef } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import { isMobileDevice } from '../utils';

const VideoSlider: React.FC = () => {

    const imageGalleryRef = useRef(null);

    const desktopImages = [
        {
            id: 'vid01',
            original: `/videos/slider/scene01 thumb.png`,
            embedUrl: '/videos/slider/scene01.mp4',
            renderItem: _renderVideo,
        }
    ]

    const mobileImages = [
        {
            id: 'vid01',
            original: `/videos/slider/scene01 mobile thumb.png`,
            embedUrl: '/videos/slider/scene01 mobile.mp4',
            renderItem: _renderVideo,
        }
    ]

    function getImages() {
        return isMobileDevice() ? mobileImages : desktopImages;
    }


    function startVideo(e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) {

        e.preventDefault();

        // stop slider animation
        if (imageGalleryRef && imageGalleryRef.current) {
            (imageGalleryRef.current as any).pause();
        }

        const vid = document.getElementById(id) as any;

        if (!vid?.getAttribute("clicked")) {

            vid?.setAttribute("clicked", true);

            if (vid?.paused) {
                vid?.play();
            } else {
                vid?.pause();
            }

            setTimeout(() => {
                vid?.setAttribute("controls", true);
            }, 200)

            console.log("start video: " + id);

        }
    }

    function stopAllVideos() {
        const vids = document.getElementsByClassName("image-gallery-video") as any;

        // for (let i = 0; i < vids.length; i++) {
        //     const vid = vids[i];
        //     vid?.pause();
        // }
        console.log("stop all videos");
    }


    function _renderVideo(item) {

        return (
            <div className="kt-slider-video-wrapper">
                <div
                    onClick={(e) => startVideo(e, item.id)}
                >
                    <video
                        id={item.id}
                        className='image-gallery-video'
                        src={item.embedUrl}
                        playsInline
                        autoPlay
                        muted
                        controls={false}
                    ></video>
                    {item.description && <span className="image-gallery-description">{item.description}</span>}
                </div>
            </div>
        );
    }

    return (

        <section className="app" >
            <ImageGallery
                ref={imageGalleryRef}
                items={getImages()}
                showFullscreenButton={false}
                showPlayButton={false}
                showThumbnails={false}
                showBullets={false}
                showIndex={false}
                showNav={false}
                isRTL={false}
                autoPlay={true}
                slideDuration={450}
                slideInterval={4000}
                additionalClass="app-image-gallery"
                useWindowKeyDown={true}
                //onBeforeSlide={stopAllVideos}
            />

        </section >
    );

}

export default VideoSlider;