import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";

import firebaseApp from "./database";

import PatientsService from "./../services/patientsService";

import AccountVerificationPanel from "./accountVerificationPanel";

export default function LoginUserPanel(props) {

    const {appointmentId} = useParams();

    const navigate = useNavigate();

    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [mobilePhoneNumber, setMobilePhoneNumber] = useState("");

    const [isConfirmationPanelVisible, setIsConfirmationPanelVisible] = useState(false);

    useEffect(()=> {
        setIsConfirmationPanelVisible(false);
    }, []);

    function onLoginFormSubmit(event) {
        event.preventDefault();

        firebaseApp.auth().signInWithEmailAndPassword(loginEmail, loginPassword)
        .then((result)=>{

            if(!result.user.phoneNumber) {

                PatientsService.getPatient(result.user.uid)
                .then((patient) => {
                    setMobilePhoneNumber(patient.mobilePhoneNumber);
                    setIsConfirmationPanelVisible(true);
                })
                .catch(() => {
                    alert("Dies ist kein gültiger Patienten Account. Bitte benutzen Sie eine andere Email Adresse.");
                })

            } else {
                // logged in, now goto patient appointments page or confirmation page

                if(appointmentId) {
                    navigate(`/confirmation/${appointmentId}`);
                }else {
                    navigate(`/appointments`);
                }

            }

        })
        .catch((error) => {
            var errorCode = error.code;

            switch (errorCode) {
                case "auth/invalid-email":
                case "auth/user-not-found":
                case "auth/wrong-password":
                    alert("Die von Ihnen eingegebene E-Mail-Adresse oder das Passwort ist falsch. Bitte versuchen Sie es erneut");
                    break;

                case "auth/user-disabled":
                    alert("Dieser Account ist gesperrt, bitte wenden Sie Sich telefonisch an uns.");
                    break;

                default:
                    alert(error.message);
                    break;
            }

        });
    }

    return (

        isConfirmationPanelVisible ?
        <AccountVerificationPanel email={loginEmail} password={loginPassword} mobilePhoneNumber={mobilePhoneNumber}/>
        :
        <div className="kt-panel">
            <div className="kt-panel-content">
                <div className="kt-center">Sie haben bereits ein Pick-A-Doc Konto?</div>

                <form name="login" onSubmit={onLoginFormSubmit}>
                    <input type="email" placeholder="E-Mail-Adresse" required value={loginEmail} onChange={e => setLoginEmail(e.target.value)}/>
                    <input type="password" placeholder="Passwort" required value={loginPassword} onChange={e => setLoginPassword(e.target.value)}/>
                    <Link to={"/password-recovery"} className="kt-align-right">Passwort vergessen?</Link>
                    <input className="kt-btn-big kt-btn-secondary-color" type="submit" value="ANMELDEN" />
                </form>

                <div className="kt-history-back-link" onClick={e => window.history.back()}><i className="far fa-angle-left"></i> Zurück</div>
            </div>
        </div>

    );
}