import firebase from 'firebase/compat/app';
import firebaseApp from "./../components/database";
//import "firebase/compat/firestore";

import { getDate, getDateString, getTimeString } from './../utils';
import PatientsService from "./patientsService";
import LoggingService from "./loggingService";
import { LogItemType } from "../models/logItem";
import UsersService from "./usersService";

const db = firebaseApp.firestore();
const functions = firebase.app().functions('europe-west3');

const AppointmentsService = {


    async getAppointmentsByDate(fromDate: Date, toDate: Date, calendarIds: string[]): Promise<any[] | null> {

        console.log("AppointmentsService.getAppointmentsByDate");

        try {

            let query = db.collection("appointments")
                .orderBy("start")
                .where("start", ">", fromDate)
                .where("start", "<", toDate);


            if (calendarIds) {
                query = query.where("calendar.id", "in", calendarIds)
            }

            const querySnapshot = await query.get();

            const appointmentList: any[] = [];

            querySnapshot.forEach((doc) => {
                const appointment = AppointmentsService.getAppointmentFromDocument(doc);
                if (appointment.status !== "declined") {
                    appointmentList.push(appointment);
                }
            });

            return appointmentList;

        } catch (error) {
            console.log("Error getting appointments: ", error);
            return null;
        }
    },

    getAppointmentFromDocument(doc) {
        const appointment = doc.data();

        appointment.id = doc.id;
        appointment.start = getDate(appointment.start);
        appointment.end = getDate(appointment.end);

        if (!appointment.title && appointment.patient) {
            appointment.title = `${appointment.patient.lastName.toUpperCase()} ${appointment.patient.firstName}`;
        }
        if (appointment.visitMotive) {
            appointment.color = appointment.visitMotive.color;
        }
        if (appointment.calendar) {
            if (appointment.calendar.parentId) {
                appointment.resourceIds = [appointment.calendar.parentId, appointment.calendar.id];
                appointment.resourceId = null;
            } else {
                appointment.resourceId = appointment.calendar.id;
                appointment.resourceIds = null;
            }
        }

        appointment.remindLaterCount = appointment.remindLaterCount ?? 0;

        return appointment;
    },

    async getPublicAppointments(clientId: string, locationId: string, fromDate: Date, toDate: Date, calendarIds: string[]): Promise<any[] | null> {

        try {

            const getPublicAppointmentsFunc = functions.httpsCallable("getPublicAppointments");
            const result = await getPublicAppointmentsFunc(
                {
                    clientId: clientId,
                    locationId: locationId,
                    fromDate: fromDate.getTime(),
                    toDate: toDate.getTime(),
                    calendarIds: calendarIds
                });

            const appointments: any[] = []

            result.data.forEach(appointment => {
                appointment.start = getDate(appointment.start);
                appointment.end = getDate(appointment.end);

                if (appointment.status !== "declined") {
                    appointments.push(appointment);
                }
            });

            console.log(`getPublicAppointments: found ${appointments.length}`);

            return appointments;


        } catch (error) {
            console.log("Error getting public appointments: ", error);
            return null;
        }
    },


    async getLocationAppointment(clientId: string, locationId: string, appointmentId: string): Promise<any | null> {

        console.log("AppointmentsService.getLocationAppointment");

        try {

            const getLocationAppointmentFunc = functions.httpsCallable("getLocationAppointment");
            const result = await getLocationAppointmentFunc(
                {
                    clientId: clientId,
                    locationId: locationId,
                    appointmentId: appointmentId
                });

            return result.data;

        } catch (error) {
            console.log("Error getting location appointment: ", error);
        }

        return null;
    },

    async setLocationAppointmentStatus(appointment: any, patient: any, newStatus: string): Promise<void> {
        try {

            const _patient = patient ? patient : appointment.patient;

            const setLocationAppointmentStatusFunc = functions.httpsCallable("setLocationAppointmentStatus");
            const result = await setLocationAppointmentStatusFunc(
                {
                    clientId: appointment.clientId,
                    locationId: appointment.locationId,
                    appointmentId: appointment.id,
                    newStatus: newStatus
                });

            // for backwards compatability
            const calendarId = appointment.calendarId ? appointment.calendarId : (appointment.calendar ? appointment.calendar.id : "");

            if (newStatus === "declined" && calendarId) {
                const doctor = await UsersService.getUserByCalendarId(appointment.clientId, appointment.locationId, calendarId);

                if (doctor) {
                    const patientName = (_patient && _patient.lastName) ? `${_patient.firstName} ${_patient.lastName} hat den ` : "";
                    const doctorName = doctor.title ? `${doctor.title} ${doctor.lastName}` : doctor.lastName;

                    let visitMotiveName = (appointment.visitMotive && appointment.visitMotive.name) ? `${appointment.visitMotive.name} ` : "";
                    visitMotiveName = (visitMotiveName === "" && appointment.visitMotiveName) ? `${appointment.visitMotiveName} ` : visitMotiveName;

                    const message = `${patientName}Termin ${visitMotiveName}für den ${getDateString(appointment.start)} ${getTimeString(appointment.start)} bei ${doctorName} abgesagt.`;

                    LoggingService.log(LogItemType.patientDeletedAppointment, appointment.id, _patient.id, appointment.calendar?.userId, message, appointment.clientId, appointment.locationId);

                    if (_patient.id) {
                        PatientsService.deleteFromPatientAppointmentList(appointment.id, _patient.id);
                        AppointmentsService.deleteReminder(appointment.id);
                    }
                }
            }

        } catch (error) {
            console.log("Error setting location appointment status: ", error);
        }
    },

    async confirmAppointment(appointment): Promise<void> {
        try {

            const confirmAppointmentFunc = functions.httpsCallable("confirmAppointment");
            const result = await confirmAppointmentFunc(
                {
                    appointmentId: appointment.id,
                    clientId: appointment.clientId,
                    locationId: appointment.locationId
                });

        } catch (error) {
            console.log("Error confirming appointment: ", error);
        }
    },

    async remindAgainLater(appointment): Promise<void> {
        try {

            const remindAgainLaterFunc = functions.httpsCallable("remindAgainLater");
            const result = await remindAgainLaterFunc(
                {
                    appointmentId: appointment.id,
                    clientId: appointment.clientId,
                    locationId: appointment.locationId
                });

        } catch (error) {
            console.log("Error in remindAgainLater: ", error);
        }
    },

    async confirmRecallAppointment(appointment): Promise<void> {
        try {

            const confirmRecallAppointmentFunc = functions.httpsCallable("confirmRecallAppointment");
            const result = await confirmRecallAppointmentFunc(
                {
                    start: appointment.start.getTime(),
                    end: appointment.end.getTime(),
                    appointmentId: appointment.id,
                    clientId: appointment.clientId,
                    locationId: appointment.locationId
                });

        } catch (error) {
            console.log("Error confirming recall appointment: ", error);
        }
    },

    async postponeAppointment(appointment): Promise<void> {
        try {

            const postponeAppointmentFunc = functions.httpsCallable("postponeAppointment");
            const result = await postponeAppointmentFunc(
                {
                    start: appointment.start.getTime(),
                    end: appointment.end.getTime(),
                    appointmentId: appointment.id,
                    clientId: appointment.clientId,
                    locationId: appointment.locationId
                });

        } catch (error) {
            console.log("Error postponing appointment: ", error);
        }
    },

    // gets a appointment from the db root, where we store the reserved appointments
    // a cron job then cleans them up after some time, if the appointment is not confirmed
    async getAppointment(appointmentId: string): Promise<any | null> {

        console.log("AppointmentsService.getAppointment");

        try {

            const doc = await db.collection("appointments")
                .doc(appointmentId)
                .get();


            if (doc.exists) {
                const appointment: any = doc.data();
                appointment.id = appointmentId;
                return appointment;

            } else {
                console.log("getAppointment: No such document: " + appointmentId);
            }


        } catch (error) {
            console.log("Error getting appointment: ", error);
        }

        return null;
    },

    async getAppointments(patientId: string): Promise<any[] | null> {

        try {
            console.log("AppointmentsService.getAppointments");

            const appointmentList: any[] = [];

            const db = firebaseApp.firestore();
            const querySnapshot = await db.collection("patients")
                .doc(patientId)
                .collection("appointments")
                .get();

            querySnapshot.forEach((doc) => {
                const appointment = doc.data();
                appointment.id = doc.id;

                if (appointment.status !== "declined") {
                    appointmentList.push(appointment);
                }
            });

            console.log(`appointments: ${appointmentList.length}`);

            return appointmentList;

        } catch (error) {
            console.log(`error in getAppointments: ${error}`);
            return null;
        }
    },

    // use toDbRoot=true if we create a temporary appointment which is then reserved for some minutes
    // the patient has to confirm the appointment or it will be deleted again after the expiration time
    // we do not need to take care of recalls and follow up appointments here, we create them only
    // if the doctor sets the status to "treated" in the calendar app
    async updateAppointment(appointment: any, patient: any, toDbRoot: boolean): Promise<string | null> {

        console.log("AppointmentsService.updateAppointment");

        try {

            let appPat: any = null;

            // remove children and parent references
            if (appointment.calendar) {

                var parentId = appointment.calendar.parent ? appointment.calendar.parent.id : null;

                appointment.calendar = {
                    id: appointment.calendar.id,
                    name: appointment.calendar.name,
                    userId: appointment.calendar.userId
                }

                appointment.createdBy = "online";

                if (patient) {

                    //const clientPat = await PatientsService.getClientLocationPatientByMobilePhoneNumberAndName(appointment.clientId, appointment.locationId, patient);
                    const clientPat = await PatientsService.getOrCreateClientLocationPatient(appointment.clientId, appointment.locationId, patient);

                    appPat = clientPat ?? patient;

                    if (appPat) {
                        appointment.patient = {
                            id: appPat.id,
                            gender: appPat.gender,
                            firstName: appPat.firstName,
                            lastName: appPat.lastName,
                            mobilePhoneNumber: appPat.mobilePhoneNumber,
                            email: appPat.email,
                            emailAllowed: appPat.emailAllowed,
                            reminderAllowed: appPat.reminderAllowed,
                            smsAllowed: appPat.smsAllowed,
                            newPatient: appPat.newPatient === undefined || appPat.newPatient === true,
                            privateInsurance: appPat.privateInsurance === true
                        }

                        if (appPat.documentsExpireAt) {
                            appointment.documentsExpireAt = appPat.documentsExpireAt;
                        }
                    }
                }

                if (parentId) {
                    appointment.calendar.parentId = parentId;
                }
            }

            var queryString = toDbRoot ? `appointments` : `clients/${appointment.clientId}/locations/${appointment.locationId}/appointments`;

            if (appointment.id) {
                // update existing appointment
                await db.collection(queryString)
                    .doc(appointment.id)
                    .set(appointment, { merge: true });

                if (patient && !toDbRoot) {
                    PatientsService.addToPatientAppointmentList(patient, appointment);
                    AppointmentsService.updateReminder(appointment, patient);
                }

                return appointment.id;


            } else {
                // create a new appointment
                appointment.createdAt = new Date(); //FieldValue.serverTimestamp();

                if (appPat) {
                    appointment.patient.newPatient = (appPat.lastAppointmentDate === null || appPat.lastAppointmentDate === undefined || (typeof appPat.lastAppointmentDate.getUTCFullYear === "function" && appPat.lastAppointmentDate.getUTCFullYear() === 1900));
                }

                const docRef = await db.collection(queryString)
                    .add(appointment);


                appointment.id = docRef.id;

                if (patient && !toDbRoot) {
                    PatientsService.addToPatientAppointmentList(patient, appointment);
                    AppointmentsService.updateReminder(appointment, patient);
                }

                return appointment.id;
            }

        } catch (error) {
            console.log("Error updating appointment: ", error);
            return null;
        }

    },


    getReminderSendDate(appointment): Date {

        const startDate = getDate(appointment.start);
        const sendDate = new Date(startDate);

        if (appointment.parentRecallId && appointment.createdBy === "recaller" && appointment.status === "needsConfirmation") {

            sendDate.setHours(startDate.getHours() - 24 * 7);

        } else if (appointment.createdBy === "predecessor" && appointment.status === "needsConfirmation") {

            sendDate.setHours(startDate.getHours() - 24 * 7);

        } else {
            sendDate.setHours(startDate.getHours() - 24 * 1);
        }

        return sendDate;
    },


    // reminders is a list of email and/or sms items which have to be send in the future
    async updateReminder(appointment, patient): Promise<boolean> {

        console.log("AppointmentsService.updateReminder");

        try {

            const startDate = getDate(appointment.start);
            const sendDate = this.getReminderSendDate(appointment);

            const now = new Date();
            const isInFuture = startDate.getTime() > now.getTime();

            var sendItem = {
                id: appointment.id,
                clientId: appointment.clientId,
                patient: {
                    id: patient.id,
                    firstName: patient.firstName,
                    lastName: patient.lastName,
                    email: "",
                    mobilePhoneNumber: ""
                },
                appointmentStart: appointment.start,
                sendTimeStamp: sendDate,
                locationId: appointment.locationId
            };

            if (patient.reminderAllowed && patient.emailAllowed && patient.email) {
                sendItem.patient.email = patient.email;
            }

            if (patient.reminderAllowed && patient.smsAllowed && patient.mobilePhoneNumber) {
                sendItem.patient.mobilePhoneNumber = patient.mobilePhoneNumber;
            }

            if (isInFuture && (sendItem.patient.email || sendItem.patient.mobilePhoneNumber)) {
                await db.collection("reminders")
                    .doc(appointment.id)
                    .set(sendItem);


            } else {

                await db.collection("reminders")
                    .doc(appointment.id)
                    .delete();
            }

            return true;

        } catch (error) {
            console.log("Error updating reminder: ", error);
            return false;
        }
    },

    async deleteReminder(appointmentId: string): Promise<void> {
        try {

            await db.collection("reminders")
                .doc(appointmentId)
                .delete();

        } catch (error) {
            console.log("Error deleting deleteReminder: ", error);
        }
    },

    async deleteAppointment(appointment, patient, fromDbRoot): Promise<void> {

        const appointmentId = appointment.id;
        const patientId = patient ? patient.id : null;

        try {

            const queryString = fromDbRoot ? `appointments` : `clients/${appointment.clientId}/locations/${appointment.locationId}/appointments`;

            await db.collection(queryString)
                .doc(appointmentId)
                .delete();

            if (patientId && !fromDbRoot) {
                PatientsService.deleteFromPatientAppointmentList(appointmentId, patientId);
                AppointmentsService.deleteReminder(appointmentId);
            }

        } catch (error) {
            console.log("Error deleting appointment: ", error);
        }

    },

    async moveUserAppointments(sourceId: string, destinationId: string): Promise<void> {

        try {

            const moveUserAppointmentsFunc = functions.httpsCallable("moveUserAppointments");
            await moveUserAppointmentsFunc(
                {
                    sourceId: sourceId,
                    destinationId: destinationId
                });

        } catch (error) {
            console.log("Error moving patient user appointments: ", error);
        }
    }

}

export default AppointmentsService;