import firebaseApp from "./../components/database";
import firebase from "firebase/compat/app";

const functions = firebase.app().functions('europe-west3');

const db = firebaseApp.firestore();

const CampaignsService = {

    async getCampaign(campaignId: string, clientId: string, locationId: string): Promise<any | null> {

        try {
            const doc = await db.collection("clients")
                .doc(clientId)
                .collection("locations")
                .doc(locationId)
                .collection("campaigns")
                .doc(campaignId)
                .get();

            if (doc.exists) {

                let campaign: any = null;

                if (doc.exists) {
                    campaign = doc.data();
                    campaign.id = campaignId;
                }

                return campaign;

            } else {
                console.log("getCampaign: No such document: " + campaignId);
            }

        } catch (error) {
            console.log("Error getting campaign: ", error);
        }

        return null;
    },

    async updateCampaignPatientProperties(campaignPatientProperties: any, campaignId: string, clientId: string, locationId: string): Promise<void> {

        try {
            const data = {
                campaignPatientProperties: campaignPatientProperties,
                campaignId: campaignId,
                clientId: clientId,
                locationId: locationId
            }

            const updateCampaignPatientFunc = functions.httpsCallable("updateCampaignPatientProperties");
            await updateCampaignPatientFunc(data);

        } catch (error) {
            console.log("Error updating campaign patient properties: ", error);
        }

    },
}

export default CampaignsService;