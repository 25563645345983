import React, { useState } from 'react';
import SwitchCtrl from './switchCtrl';


const PriceCtrl: React.FC = () => {

    const maxDoctors = 20;
    const maxAppointments = 60;
    const maxPrice = 2500;

    const [doctors, setDoctors] = useState(1);
    const [appointments, setAppointments] = useState(8);

    // A = cheap
    const [recallsEnabledA, setRecallsEnabledA] = useState(false);
    const [documentsEnabledA, setDocumentsEnabledA] = useState(false);
    const [confirmationSmsEnabledA, setConfirmationSmsEnabledA] = useState(true);
    const [ratingSmsEnabledA, setRatingSmsEnabledA] = useState(false);
    const [esignSmsEnabledA, setEsignSmsEnabledA] = useState(false);
    const [reminderSmsEnabledA, setReminderSmsEnabledA] = useState(true);

    // B = full performance
    const [recallsEnabledB, setRecallsEnabledB] = useState(true);
    const [documentsEnabledB, setDocumentsEnabledB] = useState(true);
    const [confirmationSmsEnabledB, setConfirmationSmsEnabledB] = useState(true);
    const [ratingSmsEnabledB, setRatingSmsEnabledB] = useState(true);
    const [esignSmsEnabledB, setEsignSmsEnabledB] = useState(true);
    const [reminderSmsEnabledB, setReminderSmsEnabledB] = useState(true);

    const appointmentsFactor = 0.5;
    const documentsPerAppointment = 3;
    const workDays = 20;

    const appointmentPrice = 0.15;
    const documentPrice = 0.35;
    const confirmationSmsPrice = 0.19;
    const esignSmsPrice = 0.24;
    const ratingSmsPrice = 0.24;
    const reminderSmsPrice = 0.19;

    function decreaseDoctors(){
        if(doctors > 1) {
            setDoctors(doctors - 1);
        }
    }
    function increaseDoctors(){
        if(doctors < maxDoctors) {
            setDoctors(doctors + 1);
        }
    }

    function decreaseAppointments(){
        if(appointments > 0) {
            setAppointments(appointments - 1);
        }
    }
    function increaseAppointments(){
        if(appointments < maxAppointments) {
            setAppointments(appointments + 1);
        }
    }


    function getPriceALabel() {

        let totalAppointmentsA = doctors * appointments * workDays;

        if (recallsEnabledA) {
            totalAppointmentsA += totalAppointmentsA * 0.05;
        }

        const appointmentsCost = totalAppointmentsA * appointmentPrice;
        const documentsCost = documentsEnabledA ? totalAppointmentsA * appointmentsFactor * documentsPerAppointment * documentPrice : 0;

        const confirmationSmsCost = confirmationSmsEnabledA ? totalAppointmentsA * confirmationSmsPrice : 0;
        const esignSmsCost = esignSmsEnabledA ? totalAppointmentsA * appointmentsFactor * esignSmsPrice : 0;
        const ratingSmsCost = ratingSmsEnabledA ? totalAppointmentsA * appointmentsFactor * ratingSmsPrice : 0;
        const reminderSmsCost = reminderSmsEnabledA ? totalAppointmentsA * reminderSmsPrice : 0;

        const price = appointmentsCost + documentsCost + confirmationSmsCost + esignSmsCost + ratingSmsCost + reminderSmsCost;
        return price < maxPrice ? `${price.toFixed(2)} €` : <span className='kt-individual-price'>Individuelles Angebot anfordern</span>;
    }

    function getPriceBLabel() {

        let totalAppointmentsB = doctors * appointments * workDays;

        if (recallsEnabledB) {
            totalAppointmentsB += totalAppointmentsB * 0.05;
        }

        const appointmentsCost = totalAppointmentsB * appointmentPrice;
        const documentsCost = documentsEnabledB ? totalAppointmentsB * appointmentsFactor * documentsPerAppointment * documentPrice : 0;

        const confirmationSmsCost = confirmationSmsEnabledB ? totalAppointmentsB * confirmationSmsPrice : 0;
        const esignSmsCost = esignSmsEnabledB ? totalAppointmentsB * appointmentsFactor * esignSmsPrice : 0;
        const ratingSmsCost = ratingSmsEnabledB ? totalAppointmentsB * appointmentsFactor * ratingSmsPrice : 0;
        const reminderSmsCost = reminderSmsEnabledB ? totalAppointmentsB * reminderSmsPrice : 0;

        const price = appointmentsCost + documentsCost + confirmationSmsCost + esignSmsCost + ratingSmsCost + reminderSmsCost;
        return price < maxPrice ? `${price.toFixed(2)} €` : <span className='kt-individual-price'>Individuelles Angebot anfordern</span>
    }

    return (

        <div className="kt-price-ctrl">

            <h2>Ermitteln Sie hier ganz einfach Ihren individuellen Preis</h2>


            <div className="kt-slidecontainer">
                <i className='far fa-user-md kt-click' onClick={()=>decreaseDoctors()}></i>
                <input
                    type="range"
                    min="1"
                    max={maxDoctors}
                    value={doctors}
                    onChange={e => setDoctors(parseInt(e.currentTarget.value))}
                    className="kt-slider"
                />
                <span onClick={()=>increaseDoctors()} className="kt-click">
                    <i className='far fa-user-md'></i> <i className='far fa-user-md'></i>
                </span>
            </div>
            <div className='kt-price-label-value'>Anzahl Ärzte/Behandler: <span>{doctors}</span></div>

            <div className="kt-slidecontainer">
                <i className='far fa-calendar-alt kt-click' onClick={()=>decreaseAppointments()}></i>
                <input
                    type="range"
                    min="0"
                    max={maxAppointments}
                    value={appointments}
                    onChange={e => setAppointments(parseInt(e.currentTarget.value))}
                    className="kt-slider"
                />
                <span className='kt-click' onClick={()=>increaseAppointments()}>
                    <i className='far fa-calendar-alt'></i> <i className='far fa-calendar-alt'></i>
                </span>
            </div>
            <div className='kt-price-label-value'>Termine pro Tag (je Arzt): <span>{appointments}</span></div>


            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Preisbewust</th>
                        <th>Performance+</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>Recalls &amp; Folgetermine</td>
                        <td><SwitchCtrl
                            label=""
                            checked={recallsEnabledA}
                            onChange={newValue => setRecallsEnabledA(newValue)}
                        /></td>
                        <td><SwitchCtrl
                            label=""
                            checked={recallsEnabledB}
                            onChange={newValue => setRecallsEnabledB(newValue)}
                        /></td>
                    </tr>

                    <tr>
                        <td>eSign</td>
                        <td><SwitchCtrl
                            label=""
                            checked={documentsEnabledA}
                            onChange={newValue => setDocumentsEnabledA(newValue)}
                        /></td>
                        <td><SwitchCtrl
                            label=""
                            checked={documentsEnabledB}
                            onChange={newValue => setDocumentsEnabledB(newValue)}
                        /></td>
                    </tr>

                    <tr>
                        <td>Bestätigungs SMS</td>
                        <td><SwitchCtrl
                            label=""
                            checked={confirmationSmsEnabledA}
                            onChange={newValue => setConfirmationSmsEnabledA(newValue)}
                        /></td>
                        <td><SwitchCtrl
                            label=""
                            checked={confirmationSmsEnabledB}
                            onChange={newValue => setConfirmationSmsEnabledB(newValue)}
                        /></td>
                    </tr>

                    <tr>
                        <td>Erinnerungs SMS</td>
                        <td><SwitchCtrl
                            label=""
                            checked={reminderSmsEnabledA}
                            onChange={newValue => setReminderSmsEnabledA(newValue)}
                        /></td>
                        <td><SwitchCtrl
                            label=""
                            checked={reminderSmsEnabledB}
                            onChange={newValue => setReminderSmsEnabledB(newValue)}
                        /></td>
                    </tr>

                    <tr>
                        <td>Bewertungs SMS</td>
                        <td><SwitchCtrl
                            label=""
                            checked={ratingSmsEnabledA}
                            onChange={newValue => setRatingSmsEnabledA(newValue)}
                        /></td>
                        <td><SwitchCtrl
                            label=""
                            checked={ratingSmsEnabledB}
                            onChange={newValue => setRatingSmsEnabledB(newValue)}
                        /></td>
                    </tr>

                    <tr>
                        <td>eSign SMS</td>
                        <td><SwitchCtrl
                            label=""
                            checked={esignSmsEnabledA}
                            onChange={newValue => setEsignSmsEnabledA(newValue)}
                        /></td>
                        <td><SwitchCtrl
                            label=""
                            checked={esignSmsEnabledB}
                            onChange={newValue => setEsignSmsEnabledB(newValue)}
                        /></td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr>
                        <td>monatlich:</td>
                        <td>{getPriceALabel()}</td>
                        <td>{getPriceBLabel()}</td>
                    </tr>
                </tfoot>

            </table>


        </div>

    );
}

export default PriceCtrl;