import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
//import "firebase/compat/analytics";

const config = {
    apiKey: "AIzaSyCG65EB0gojy6cIUO23eJ1gM2mMi7DjLLI",
    authDomain: "docgenda.firebaseapp.com",
    databaseURL: "https://docgenda.firebaseio.com",
    projectId: "docgenda",
    storageBucket: "docgenda.appspot.com",
    messagingSenderId: "659741174605",
    appId: "1:659741174605:web:495b33c5dcbd401e0ae504",
    measurementId: "G-QEWC42M632"
};

const firebaseApp = firebase.initializeApp(config);

// Initialize Analytics and get a reference to the service
//export const analytics = firebase.analytics();

firebase.firestore().settings({ experimentalAutoDetectLongPolling: true });

export default firebaseApp;
