import { LogItemType } from "../models/logItem";
import firebaseApp from "./../components/database";
import LoggingService from "./loggingService";

const db = firebaseApp.firestore();

const RatingsService = {

    async getRating(ratingId): Promise<any | null> {

        try{

            const doc = await db.collection("ratings")
                            .doc(ratingId)
                            .get();

            if (doc.exists) {
                const rating = doc.data();
                rating!.id = ratingId;

                return rating;

            } else {
                console.log("getRating: No such document: " + ratingId);
                return null;
            }

        } catch(error) {
            console.log("Error getting rating: ", error);
            return null;
        }
    },

    async setRating(rating): Promise<boolean> {
        if(rating){
            try {
                await db.collection("ratings")
                        .doc(rating.id)
                        .set(rating, {merge: true});

                const logMessage = `Patient ${rating.patientLastName} hat ${rating.doctorName} mit ${rating.rating} ${rating.rating === 1 ? "Stern" : "Sternen"} bewertet.`;
                LoggingService.log(LogItemType.doctorHasBeenRated, rating.appointmentId, rating.patientId, rating.doctorId, logMessage, rating.clientId, rating.locationId);
                return true;

            } catch(error) {
                console.error("Error setting rating: ", error);
            }
        }

        return false;
    }

}

export default RatingsService;