import React, { useState } from 'react';

import firebaseApp from "./../database";

export default function PasswordRecoveryPage(props) {

    const [loginEmail, setLoginEmail] = useState("");
    const [mailHasBeenSent, setEmailHasBeenSent] = useState(false);

    function onLoginFormSubmit(event) {
        event.preventDefault();

        firebaseApp.auth().sendPasswordResetEmail(loginEmail, {url: "https://patient-docgenda.web.app/login"})
        .then(()=>{
            setEmailHasBeenSent(true);
        })
        .catch((error) => {
            var errorCode = error.code;

            switch (errorCode) {
                case "auth/invalid-email":
                case "auth/user-not-found":
                    alert("Die von Ihnen eingegebene E-Mail-Adresse ist uns nicht bekannt. Bitte versuchen Sie es erneut");
                    break;

                case "auth/user-disabled":
                    alert("Dieser Account ist gesperrt, bitte wenden Sie Sich telefonisch an uns.");
                    break;

                default:
                    alert(error.message);
                    break;
            }

        });
    }

    return (

        <div className="kt-passwordRecoveryPage">

            <div className="kt-body">

                <div className="kt-panel">
                    <div className="kt-panel-content">

                        {mailHasBeenSent ?
                            <div>
                                <h2>Vielen Dank!</h2>
                                <div>Sie erhalten in Kürze eine Email von uns.</div>
                            </div>
                            :
                            <div>
                                <h2>Passwort vergessen?</h2>
                                <div>Hier können Sie Ihr Passwort zurücksetzen.</div>
                                <form name="login" onSubmit={onLoginFormSubmit}>
                                    <input type="email" placeholder="E-Mail-Adresse" required value={loginEmail} onChange={e => setLoginEmail(e.target.value)}/>

                                    <input className="kt-btn-big kt-btn-secondary-color" type="submit" value="ZURÜCKSETZEN" />
                                </form>
                            </div>
                        }

                        <div className="kt-history-back-link" onClick={e => window.history.back()}><i className="far fa-angle-left"></i> Zurück</div>

                    </div>
                </div>

            </div>

        </div>
    );
}