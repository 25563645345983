import firebase from "firebase/compat/app";
import Lead from "../models/lead";

const functions = firebase.app().functions('europe-west3');


const LeadsService = {

    async addLead(newLead: Lead): Promise<boolean> {

        try {

            const addLeadFunc = functions.httpsCallable("addLead");
            
            await addLeadFunc(newLead);

            return true;

        } catch (error) {
            console.error("Error adding lead data: ", error);
        }


        return false;
    }
}

export default LeadsService;