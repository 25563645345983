import firebase from "firebase/compat/app";
import InstallationModel from "../models/installationModel";

const functions = firebase.app().functions('europe-west3');

const InstallationsService = {

    async startWizardInstallation(installationData: InstallationModel): Promise<boolean> {

        try {

            const installFunc = functions.httpsCallable("startWizardInstallation");
            await installFunc(installationData);

            return true;

        } catch(error) {
            console.log("Error in startWizardInstallation: ", error);

            return false;
        }
    },

    async sendDemoSms(data: any): Promise<boolean> {

        try {

            const sendDemoSmsFunc = functions.httpsCallable("sendDemoSms");
            await sendDemoSmsFunc(data);

            return true;

        } catch(error) {
            console.log("Error in sendDemoSms: ", error);

            return false;
        }
    }

}

export default InstallationsService;