import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import firebaseApp from "../database";

import LoginUserPanel from "../loginUserPanel";


export default function LoginPage(props) {

    const { appointmentId } = useParams();
    const navigate = useNavigate();

    const [authUser, setAuthUser] = useState(null);


    useEffect(() => {
        return firebaseApp.auth().onAuthStateChanged(function(user) {
            if (user) {
                // User is signed in.
                setAuthUser(user);

                if(user && user.phoneNumber) {
                    if(appointmentId){
                        navigate(`/confirmation/${appointmentId}`);
                    } else {
                        setTimeout(() => {
                            navigate(`/appointments`);
                        }, 1000);
                    }
                }

            } else {
                // User is signed out.
                setAuthUser(null);
            }
          });

          // eslint-disable-next-line
    }, []);



    return (

        <div className="kt-loginPage">

            <div className="kt-body">

                {(authUser && authUser.phoneNumber) ?
                    <div className="kt-panel">
                        <div className="kt-panel-content">
                            <div><h2>Sie sind angemeldet.</h2></div>
                            <div className="kt-history-back-link" onClick={e => window.history.back()}><i className="far fa-angle-left"></i> Zurück</div>
                        </div>
                    </div>
                    :
                    <LoginUserPanel/>
                }


            </div>

        </div>
    );
}