import React from 'react';
import PriceCtrl from './priceCtrl';

interface Props {
    isVisible: boolean,
    setIsVisible: (newValue) => void;
}

const PriceDialog: React.FC<Props> = ({ isVisible, setIsVisible }) => {

    if (!isVisible) return null;

    return (
        <div className='kt-price-dialog-wrapper'>
            <div className='kt-overlay'></div>
            <div className="kt-price-dialog">

                <div className="kt-dialog-header">
                    <div className="kt-dialog-headline">Preis Kalkulator</div>
                    <div className="kt-dialog-close-button" onClick={() => setIsVisible(false)}>X</div>
                </div>

                <PriceCtrl />
                <br />
                <h3>0€ FIXKOSTEN</h3>
                <br />
                <div className="kt-dialog-footer">
                    <div className='kt-image-wrapper'>
                        <img src="images/recaller-gear-white.svg" />
                        <img src="images/p-sign-white.svg" />
                        <img src="images/struktur01-white.svg" />
                    </div>

                    <a href="/installation" className='kt-btn'>JETZT KALENDER EINRICHTEN</a>

                </div>

            </div>
        </div>
    );

}

export default PriceDialog;
