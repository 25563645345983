import firebase from 'firebase/compat/app';
import firebaseApp from "./../components/database";
import CalendarsService from "./calendarsService";
import { getDownloadURL, getStorage, ref} from "firebase/storage";

const db = firebaseApp.firestore();
const functions = firebase.app().functions('europe-west3');

export type UserPreCheckType = {
    emailUserExists: boolean,
    phoneUserExists: boolean,
    phoneBelongsToEmail: boolean,
    userHasLocationId: boolean,
    isSuperUser: boolean
}

const UsersService = {

    async getUser(clientId: string, userId: string): Promise<any | null> {

        console.log("UsersService.getUser");

        try {
            const doc = await db.collection("clients")
                .doc(clientId)
                .collection("users")
                .doc(userId)
                .get();

            if (doc.exists) {
                const user = doc.data();
                user!.id = userId;

                return user;

            } else {
                console.log("getUser: No such document: " + userId);
            }

        } catch (error) {
            console.log("Error getting user: ", error);
            return null;
        }

    },

    async getUserByCalendarId(clientId, locationId, calendarId): Promise<any | null> {

        console.log("UsersService.getUserByCalendarId");

        const calendar = await CalendarsService.getCalendar(clientId, locationId, calendarId);

        if (calendar) {
            return await UsersService.getUser(clientId, calendar.userId);
        }

        return null;
    },



    async getUsersByLocationId(clientId, locationId): Promise<any[] | null> {

        console.log("UsersService.getUsersByLocationId");

        try {
            const querySnapshot = await db.collection("clients")
                .doc(clientId)
                .collection("users")
                .where("locationId", "==", locationId)
                .get();

            const users: any[] = [];

            querySnapshot.forEach((doc) => {
                let user: any = null;

                if (doc.exists) {
                    user = doc.data();
                    user.id = doc.id;

                    users.push(user);
                }
            });

            return users;

        } catch (error) {
            console.log("Error getting user: ", error);
            return null;
        }

    },

    async getDoctorsByLocationId(clientId, locationId): Promise<any[] | null> {

        console.log("UsersService.getDoctorsByLocationId");

        try {
            const querySnapshot = await db.collection("clients")
                .doc(clientId)
                .collection("users")
                .where("locationId", "==", locationId)
                .where("role", "==", "doctor")
                .get();

            const users: any[] = [];

            querySnapshot.forEach((doc) => {
                let user: any = null;

                if (doc.exists) {
                    user = doc.data();
                    user.id = doc.id;

                    users.push(user);
                }
            });

            return users;

        } catch (error) {
            console.log("Error getting doctors: ", error);
            return null;
        }


    },


    async getUserAvatarUrl(clientId, userId, fallbackUrl): Promise<string> {

        console.log("UsersService.getUserAvatarUrl");

        const _fallbackurl = fallbackUrl ?? "";

        try {
            const storage = getStorage();

            const storageRef = ref(storage, `clients/${clientId}/users/${userId}.jpg`);

            const url = await getDownloadURL(storageRef);

            return url ?? _fallbackurl;

        } catch (error) {
            return _fallbackurl;
        }

    },

    getUserAvatarUrls(clientId: string, userIds: string[], fallbackUrl: string) {

        console.log("UsersService.getUserAvatarUrls");

        const promises: any[] = [];
        userIds.forEach(id => {
            promises.push(UsersService.getUserAvatarUrl(clientId, id, fallbackUrl));
        });

        return Promise.all(promises);
    },

    async doesUserExists(email: string, mobilePhoneNumber: string): Promise<UserPreCheckType | null> {

        try {

            const doesUserExistsFunc = functions.httpsCallable("doesUserExists");
            const result = await doesUserExistsFunc(
                {
                    email: email,
                    mobilePhoneNumber: mobilePhoneNumber
                }) as any;

            return result.data;

        } catch (error) {
            console.log("Error in doesUserExists: ", error);
        }

        return null;
    }


}

export default UsersService;