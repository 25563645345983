import firebaseApp from "./../components/database";
import firebase from "firebase/compat/app";

const functions = firebase.app().functions('europe-west3');

const db = firebaseApp.firestore();

const CalendarsService = {

    async getCalendar(clientId: string, locationId: string, calendarId: string): Promise<any | null> {

        try {

            const doc = await db.collection("clients")
                .doc(clientId)
                .collection("locations")
                .doc(locationId)
                .collection("calendars")
                .doc(calendarId)
                .get();

                if (doc.exists) {
                    const calendar = doc.data();
                    calendar!.id = calendarId;

                    return calendar;
                } else {
                    console.log("getCalendar: No such document: " + calendarId);
                }

        } catch(error){
            console.log("Error getting calendar: " + error);
        }

        return null;
    },

    async getCalendars(clientId: string, locationId: string, filterByAllowOnlineAppointments: boolean): Promise<any[] | null> {

        try{

            let queryRef: any = db.collection("clients")
                .doc(clientId)
                .collection("locations")
                .doc(locationId)
                .collection("calendars");

            if(filterByAllowOnlineAppointments){
                queryRef = queryRef.where("allowOnlineAppointments", "==", true);
            }

            const querySnapshot = await queryRef.get();

            const calendars: any[] = [];

            querySnapshot.forEach( doc => {

                if(doc.exists){
                    const calendar: any = doc.data();
                    calendar.id = doc.id;

                    calendars.push(calendar);
                }
            });

            return calendars;

        } catch(error){
            console.log("Error getting calendars: ", error);
        }

        return null;
    },

    async generatePlaygroundLandingPage(clientName: string, visitMotiveText: string, email: string): Promise<void> {

        try {

            const generatePlaygroundLandingPageFunc = functions.httpsCallable("generatePlaygroundLandingPage");
            const result: any = await generatePlaygroundLandingPageFunc(
                {
                    clientName: clientName,
                    visitMotiveText: visitMotiveText,
                    email: email
                });


        } catch (error) {
            console.log("Error generating playground landing page: ", error);            
        }

    }

}

export default CalendarsService;